import React, { useState } from "react";
import { Modal, Button, Input, Form, Row, Col, Popconfirm, Space, Table, Flex, Tag, Checkbox } from "antd";
import type { TableColumnsType, TableProps } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import './SeriesMetaDataModal.css'

interface SeriesMetaDataModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSelectSeries: (selectedSeries: string) => void;
    setIsVisible: (visible: boolean) => void;
}

interface SeriesData {
    key: string;
    name: string;
    prefix: string;
    nextNumber: string;
    documentNumber: string;
    isActive: boolean;
}

const SeriesMetaDataModal: React.FC<SeriesMetaDataModalProps> = ({ isVisible, onClose, onSelectSeries, setIsVisible }) => {
    const [form] = Form.useForm();
    const [seriesName, setSeriesName] = useState<string>("");
    const [prefix, setPrefix] = useState<string>("");
    const [number, setNumber] = useState<number>();
    const [dataSource, setDataSource] = useState<SeriesData[]>([]);
    const [activeKey, setActiveKey] = useState<string | null>(null);
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            setNumber(value);
        }
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const { seriesName, prefix, number } = values;

            if (seriesName && prefix && number) {
                const documentNumber = `${prefix}${number} - ${seriesName}`;

                const newSeries: SeriesData = {
                    key: (dataSource.length + 1).toString(),
                    name: seriesName,
                    prefix: prefix,
                    nextNumber: number,
                    documentNumber: documentNumber,
                    isActive: true,
                };

                const updatedDataSource = dataSource.map(series => ({
                    ...series,
                    isActive: false, 
                }));
                
                setDataSource([newSeries, ...updatedDataSource]);
                setActiveKey(newSeries.key); 
                onSelectSeries(documentNumber);
                setIsVisible(true);
                form.resetFields();
            } else {
                console.log("Form validation failed: Please fill all fields.");
            }
        }).catch((error) => {
            console.log("Validation Error:", error);
        });
    };

    const handleAddNewSeries = () => {
        setIsFormVisible(true);
    };

    const handleCancel = () => {
        setIsFormVisible(false);
        form.resetFields()
    };

    const handleSelectSeries = (record: SeriesData) => {
        setActiveKey(record.key);
        onSelectSeries(record.documentNumber);
    };

    const handleCheckboxChange = (key: string, documentNumber: string) => {
        setActiveKey(key);
        const updatedDataSource = dataSource.map(series => ({
            ...series,
            isActive: series.key === key,
        }));
        setDataSource(updatedDataSource);
        onSelectSeries(documentNumber);
    };

    const columns = [
        {
            title: '',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_: any, record: any) => (
                <input
                    type="checkbox"
                    checked={record.key === activeKey}
                    onChange={() => handleCheckboxChange(record.key, record.documentNumber)}
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'boldColumn'
        },
        {
            title: 'Prefix',
            dataIndex: 'prefix',
            key: 'prefix',
            className: 'boldColumn'
        },
        {
            title: 'Next Number',
            dataIndex: 'nextNumber',
            key: 'nextNumber',
            className: 'boldColumn'
        },
        {
            title: 'Document Number',
            dataIndex: 'documentNumber',
            key: 'documentNumber',
            render: (_: string, record: SeriesData) => (
                <Space>
                    {record.documentNumber}
                    {record.isActive && <Tag color="green">Active</Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a ><EditOutlined /></a>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <a key="list-loadmore-more"><DeleteOutlined /></a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Modal
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            className="modal-series-container"
            centered
        >
            <h4 className="heading-series">Customize document number</h4>
            <div className="document-series-heading-one">
                <p className="paragh-series">Create your custom document number series</p>
                <Button type="primary" onClick={handleAddNewSeries}>Add New Series</Button>
            </div>
            {isFormVisible && (
                <Form form={form} layout="vertical" requiredMark className="series-form">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Series Name" name="seriesName">
                                <Input
                                    value={seriesName}
                                    onChange={(e) => setSeriesName(e.target.value)}
                                    placeholder="Enter series name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Prefix" name="prefix" rules={[{
                                required: true,
                                message: "Please fill the prefix",
                            },]}>
                                <Input
                                    value={prefix}
                                    onChange={(e) => setPrefix(e.target.value)}
                                    placeholder="Enter prefix"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Number" name="number" rules={[
                                 {
                                    required: true,
                                    message: "Please fill the number",
                                },
                            ]}>
                                <Input
                                    type="number"
                                    value={number}
                                    onChange={handleNumberChange}
                                    placeholder="Enter number"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button onClick={handleSubmit} type="primary">
                                Submit
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            )}
            <Table className="table-document-series"
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{
                    emptyText: 'No data available', 
                }}
                rowClassName={(record, index) => (index % 2 === 0 ? 'custom-row' : 'custom-row')}
            />
        </Modal>
    );
};

export default SeriesMetaDataModal;

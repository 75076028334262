import * as type from "../types/API/storeTypes";
import { IAction } from "./userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getStoreByIdReducer = (state = initialState, action: IAction) => {
  switch(action.type) {
      case type.GET_STORE_BY_ID:
          return {
              ...state,
              loading: true,
          }
      case type.GET_STORE_BY_ID_SUCCESS:
          return {
              ...state,
              loading: false,
              data: action.payload.responseData
          }
      case type.GET_STORE_BY_ID_FAILED:
          return {
              ...state,
              loading: false,
              error: action.payload.error
          }
      default:
          return state;
  }
}

export const addStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
        error: null
      };
    case type.ADD_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
        error: null
      };
    case type.EDIT_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

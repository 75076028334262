import { Avatar, Badge, Dropdown, Layout, Popover, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import Menu, { MenuProps } from "antd/es/menu/menu";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Utility/images/ease/transparent/ease-primary-white.png";
import {
  DashboardOutlined,
  LockOutlined,
  SettingOutlined,
  PushpinOutlined,
  UserSwitchOutlined,
  GoldOutlined,
  CommentOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { UserOutlined, BellOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import BuyerSupplier from "./BuyerSupplier";
import Dashboard from "../../Dashboard/Components/Dashboard";
import Settings from "../../Dashboard/Components/Usermanagement";
import Usermanagement from "../../Dashboard/Components/Usermanagement";
import Teammanagement from "../../Dashboard/Components/teams/Teammanagement";
import Addressmanagement from "../../Dashboard/Components/Addressmanagement";
import ManageBlogs from "../../Dashboard/Components/ManageBlogs";
import { connect } from "react-redux";
import { Persistor } from "../../redux/store";
import "./Main.css";
import Permissions from "../../Dashboard/Components/Permissions";
import ItemManagement from "../../Dashboard/Components/ItemManagement";
import StoresManagement from "../../Dashboard/Components/StoresManagement";
import QuotationManage from "../../Dashboard/Components/Documents";
import SalesQuotation from "../../Dashboard/Components/sales-quotation/SalesQuotation";
import CommentsCollections from "../../Dashboard/Components/blog-comments/CommentsCollections";
import SalesEnquiry from "../../Dashboard/Components/SalesEnquiry/SalesEnquiry";
import Documents from "../../Dashboard/Components/Documents";

interface IMainInterface {
  loginData: any;
}

const Main: React.FC<IMainInterface> = ({ loginData }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any | null>(null);
  const [viewData, setViewData] = useState<any | null>(null);

  const handleLogout = () => {
    Persistor.purge().then(() => {
      localStorage.removeItem("easemargin");
      console.log("Storage key 'easemargin' cleared.");
      window.location.href = "/sign-in";
    });
  };

  const handleMenuClick = (data: any) => {
    console.log(data);
    // setSiderViewVisble(true)
    navigate("/" + data.key);
  };


  const handleAdd = (newData: any) => {
    setData((prevData) => [...prevData, { ...newData, id: Date.now() }]);
  };

  const handleEdit = (updatedData: any) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === editData?.id ? { ...item, ...updatedData } : item
      )
    );
  };

  const handleViewDetails = (item: any) => {
    console.log(item);
    setIsOpen(!isOpen);
    setViewData(item);
  };

  const handleDelete = (id: number) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const handleEditClick = (item: any) => {
    setIsOpen(!isOpen);
    setEditData(item);
  };

  const renderComponents = (elem?: string) => {
    if (window.location.href.includes("buyer_supplier")) {
      return <BuyerSupplier />;
    }
    if (window.location.href.includes("dashboard") && checkAuth()) {
      console.log(window.location.href);
      return <Dashboard />;
    }
    if (window.location.href.includes("settings")) {
      console.log(window.location.href);
      return <Settings />;
    }
    if (window.location.href.includes("user_management")) {
      console.log(window.location.href);
      return <Usermanagement />;
    }
    if (window.location.href.includes("team_management")) {
      console.log(window.location.href);
      return <Teammanagement />;
    }
    if (window.location.href.includes("address_management")) {
      console.log(window.location.href);
      return <Addressmanagement />;
    }
    if (window.location.href.includes("items")) {
      console.log(window.location.href);
      return <ItemManagement />;
    }
    if (window.location.href.includes("stores")) {
      console.log(window.location.href);
      return <StoresManagement />;
    }
    if (window.location.href.includes("manage_blogs")) {
      console.log(window.location.href);
      return <ManageBlogs />;
    }
    if (window.location.href.includes("comments")) {
      console.log(window.location.href);
      return <CommentsCollections />;
    }
    if (window.location.href.includes("permissions")) {
      console.log(window.location.href);
      return <Permissions />;
    }
    if (window.location.href.includes("sales_quotation")) {
      console.log(window.location.href);
      return <SalesQuotation />;
    }
    if (window.location.href.includes("sales_enquiry")) {
      console.log(window.location.href);
      return <SalesEnquiry />;
    }
    if (window.location.href.includes("documents")) {
      console.log(window.location.href);
      return <Documents />;
    }
  };

  const checkAuth = () => {
    return loginData?.data?.token ? true : false;
  };

  const items: MenuProps["items"] = [
    {
      label: <a href="#">Profile</a>,
      key: "0",
    },
    {
      label: <a href="#">Settings</a>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: <span onClick={handleLogout}>Logout</span>,
      key: "3",
    },
  ];


  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div>
      <Layout>
        <Header
          className="header"
          style={{
            alignItems: "center",
            position: "sticky"
          }}
        >
          <img
            src={logo}
            alt="Ease Margin"
            style={{ margin: "0px 0px 0px -30px", height: "30px" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          <div>
            <QuestionCircleOutlined style={{ color: "#FFFFFF" }} />
            <Badge count={1} className="notificationCount">
              <BellOutlined style={{ color: "#FFFFFF", marginLeft: "20px", fontSize: "15px" }} />
            </Badge>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <UserOutlined style={{ marginRight: "10px", marginLeft: "20px", color: "#FFFFFF" }} />
                <span style={{ color: "#FFFFFF" }}>{loginData.data.email}</span>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              minHeight: "100vh",
            }}
          >
            <Menu
              mode="inline"
              theme="light"
              defaultSelectedKeys={["1"]}
              style={{
                height: "100%",
                borderRight: 0,
              }}
              items={[
                {
                  key: "dashboard",
                  icon: <DashboardOutlined />,
                  label: "Dashboard",
                },
                {
                  key: "buyer_supplier",
                  icon: <UserOutlined />,
                  label: "Buyer/Supplier",
                },
                {
                  key: "inventory_management",
                  icon: <GoldOutlined />,
                  label: "Inventory",
                  children: [
                    {
                      key: "items",
                      icon: <UserSwitchOutlined />,
                      label: "Items",
                    },
                    {
                      key: "stores",
                      icon: <UserSwitchOutlined />,
                      label: "Stores",
                    },
                  ],
                },
                {
                  key: "sales",
                  icon: <ShopOutlined />,
                  label: "Sales",
                  children: [
                    {
                      key: "documents",
                      icon: <ShopOutlined />,
                      label: "Documents",
                    },
                  ],
                },
                {
                  key: "settings",
                  icon: <SettingOutlined />,
                  label: "Settings",
                  children: [
                    {
                      key: "user_management",
                      icon: <UserOutlined />,
                      label: "Users",
                    },
                    {
                      key: "address_management",
                      icon: <PushpinOutlined />,
                      label: "Address",
                    },
                    {
                      key: "permissions",
                      icon: <LockOutlined />,
                      label: "Permissions",
                    },
                  ],
                },
                { key: "manage_blogs", icon: <GoldOutlined />, label: "Blogs" },
                {
                  key: "comments",
                  icon: <CommentOutlined />,
                  label: "Comments",
                },
              ]}
              onClick={(elem: any) => {
                handleMenuClick(elem);
              }}
            />
          </Sider>
          {renderComponents()}
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

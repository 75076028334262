import {
  Breadcrumb,
  Button,
  Layout,
  Input,
  Space,
  Drawer,
  Form,
  Row,
  Col,
  Checkbox,
  Tooltip,
  Popconfirm,
  Table,
  TableProps,
  FormInstance,
  notification,
  Select,
  Tag,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { connect } from "react-redux";
import {
  addStores,
  deleteStores,
  editStores,
  getStores,
  getStoreById,
} from "../../redux/actions/API/storesActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { AppConstants } from "../../Appconstants";
import store from "../../redux/store";
import './StoreManagement.css'
import debounce from 'lodash/debounce';
import { stateData } from "../utility/StateData";

type NotificationType = "success" | "info" | "warning" | "error";

interface IStoreProps {
  storesData: any;
  getStores: Function;
  addStores: Function;
  editStore: Function;
  deleteStore: Function;
  userId: Number;
  getStoresState: IDataResponse;
  addStoreState: IDataResponse;
  editStoresState: IDataResponse;
  deleteStoresState: IDataResponse;
  companyId: any;
}

interface IStoreState {
  storeDrawerOpen: boolean;
  onEditStore: any | null;
  data: any[];
  storeName: string;
  addressLineOne: string;
  addressLineTwo: string;
  pinCode: string;
  city: string;
  state: string;
  country: string;
  loading: boolean;
  onChangeTitle: boolean;
  isCheckboxDisabled: boolean;
  storeType: any[];
  searchData: any;
  checked: boolean;
  stateList: string[];
}

interface DataType {
  key: string;
  storeId: number;
  storeName: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  pinCode: string;
  country: any;
  storeType: [];
}

const states: Record<string, string[]> = stateData;
const { Option } = Select;

class StoresManagement extends React.Component<IStoreProps, IStoreState> {
  private formRef = React.createRef<FormInstance>();
  debouncedSetState: any;
  constructor(props: IStoreProps) {
    super(props);
    this.state = {
      storeDrawerOpen: false,
      onEditStore: null,
      data: [],
      storeName: "",
      addressLineOne: "",
      addressLineTwo: "",
      pinCode: "",
      city: "",
      state: "",
      country: "",
      storeType: [],
      loading: true,
      onChangeTitle: false,
      isCheckboxDisabled: false,
      searchData: [],
      checked: true,
      stateList: states["India"]
    };
    this.debouncedSetState = debounce(this.setState.bind(this), 300);
  }

  getStoreType = (storeType: any) => {
    let types: string[] = [];

    if (
      storeType.includes(AppConstants.STORE_TYPE_MAPPING.IN_STOCK.ENTITY_ID)
    ) {
      types.push(AppConstants.STORE_TYPE_MAPPING.IN_STOCK.DISPLAY_STRING);
    }
    if (storeType.includes(AppConstants.STORE_TYPE_MAPPING.REJECT.ENTITY_ID)) {
      types.push(AppConstants.STORE_TYPE_MAPPING.REJECT.DISPLAY_STRING);
    }
    return types.join(", ");
  };

  generateBindData = () => {
    let storeData: any[] = [];
    this.props?.storesData?.map((store: any) => {
      console.log(store);
      storeData.push({
        storeId: store.id.toString(),
        name: store.name,
        addressLineOne: store.addressLineOne,
        addressLineTwo: store.addressLineTwo,
        pincode: store.pincode,
        city: store.city,
        state: store.state,
        country: store.country,
        storeType: this.getStoreType(
          store.storeType ? store.storeType?.split(",") : []
        ),
        storeAddress: `${store.addressLineOne}, ${store.addressLineTwo}, ${store.pincode}, ${store.city}, ${store.state}, ${store.country}`,
      });
    });
    return storeData;
  };

  columns: TableProps<DataType>["columns"] = [
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Store Type",
      dataIndex: "storeType",
      key: "storeType",
      render: (data: any) => (
        <Tag color="green">
          {data}
        </Tag>
      )
    },
    {
      title: "Store Address",
      dataIndex: "storeAddress",
      key: "storeAddress",
    },
    {
      title: "Actions",
      key: "action",
      render: (data: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="Edit Details">
            <span
              className="actionIcons"
              onClick={() => {
                let storeData: any = [];
                if (data.storeType.includes("In Stock")) {
                  storeData.push(1);
                }
                if (data.storeType.includes("Reject")) {
                  storeData.push(2);
                }
                this.setState({
                  storeType: storeData
                }, () => {
                  this.handleEdit(data)
                })

              }}
            ><EditOutlined /></span>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => this.handleDelete(data.storeId)}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons"><DeleteOutlined /></span>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  componentDidMount(): void {
    this.props.getStores({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<IStoreProps>,
    prevState: Readonly<IStoreState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getStoresState?.loading &&
      !this.props.getStoresState?.loading
    ) {
      if (this.props.getStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add store");
      } else {
        this.setState({
          loading: false,
          isCheckboxDisabled: this.props.storesData.length > 0 ? false : true,
          searchData: this.generateBindData(),
        });
      }
    }

    if (
      prevProps.addStoreState?.loading &&
      !this.props.addStoreState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.addStoreState?.error) {
        this.openNotificationWithIcon("error", this.props.addStoreState?.error?.message);
        this.setState({
          loading: false
        })
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.formRef.current?.resetFields();
        this.setState({
          storeDrawerOpen: false,
          loading: false,
        });
        this.openNotificationWithIcon("success", "Store added successfully");
      }
    }

    if (
      prevProps.editStoresState?.loading &&
      !this.props.editStoresState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.editStoresState?.error) {
        this.openNotificationWithIcon("error", this.props.editStoresState?.error?.message);
        this.setState({
          loading: false,
          onChangeTitle: true
        })
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.setState({
          loading: true,
          storeDrawerOpen: false,
          onEditStore: null,
        });
        this.openNotificationWithIcon("success", "Store updated successfully");
      }
    }

    if (
      prevProps.deleteStoresState?.loading &&
      !this.props.deleteStoresState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.deleteStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete store");
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.setState({
          loading: true,
        });
        this.openNotificationWithIcon("success", "Store deleted successfully");
      }
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (searchItem: string) => {
    console.log("search:", searchItem);
    const filteredData = this.generateBindData().filter((store: any) => {
      return (
        store.name.toLowerCase().includes(searchItem.toLowerCase()) ||
        store.storeType.toLowerCase().includes(searchItem.toLowerCase()) ||
        store.storeAddress.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
    this.setState({ searchData: filteredData });
  };

  handleEdit = (values: any) => {
    this.setState(
      {
        storeDrawerOpen: true,
        onChangeTitle: true,
        onEditStore: values,
      }, () => {
        this.formRef?.current?.setFieldsValue({
          storeName: values.name,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          pincode: values.pincode,
          city: values.city,
          state: values.state,
          country: values.country,
          storeType: values.storeType.split(", ").map((item: string) => {
            item = item.trim(); // Trim the item
            if (item === "In Stock") return 1; // Map "In Stock" to 1
            if (item === "Reject") return 2; // Map "Reject" to 2
            return null; // Handle unexpected values (optional)
          }).filter(Boolean), // Remove any null/undefined values
        });
      });
  };

  handleDelete = (storeId: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteStore({
      storeId: storeId,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      storeDrawerOpen: false,
      onEditStore: null,
    });
    this.formRef.current?.resetFields();
  };

  // Add Store
  handleAddStore = () => {
    this.setState({
      storeDrawerOpen: true,
      onEditStore: null,
      onChangeTitle: false,
      storeType: this.props.storesData?.length == 0 ? [2] : [],
    }, () => {
      this.formRef.current?.resetFields();
      if (this.props.storesData.length === 0) {
        this.formRef?.current?.setFieldsValue({
          storeType: "Reject".split(", ").map((item: string) => {
            item = item.trim(); // Trim the item
            if (item === "In Stock") return 1; // Map "In Stock" to 1
            if (item === "Reject") return 2; // Map "Reject" to 2
          }).filter(Boolean), // Remove any null/undefined values
        });
      }
    });
  };

  handleSubmit = () => {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({
          loading: true,
        });
        if (this.state.onEditStore || this.state.onChangeTitle) {
          // Edit existing store
          this.props.editStore({
            companyId: Number(this.props.companyId),
            storeId: this.state.onEditStore.storeId,
            name: values.storeName,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            storeType: this.state.storeType.toString(),
            ip_address: "127.0.0.1",
            status: 1,
          });
        } else {
          // Add new store
          this.props.addStores({
            companyId: Number(this.props.companyId),
            storeName: values.storeName,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            storeType: this.state.storeType.toString(),
            ip_address: "127.0.0.1",
            status: 1,
          });
          // Add the new store to the array
          this.setState((prevState) => ({
            data: [
              ...prevState.data,
              {
                id: Date.now(), // unique ID
                storeName: values.storeName,
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                pinCode: values.pincode,
                city: values.city,
                state: values.state,
                country: values.country,
                storeType: values.storeType?.join(","),
                ip_address: "127.0.0.1",
                status: 1,
              },
            ],
          }));
        }
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            className="head-section"
          >
            <Breadcrumb
              className="breadcrumbs"
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Store Management</Breadcrumb.Item>
            </Breadcrumb>

            <div className="search-input-store">
              <Input
                placeholder="Search Stores"
                className="searchInputStore"
                style={{ marginRight: "20px" }}
                onChange={(e) => {
                  this.onSearch(e.target.value);
                }}
              />
              <Button
                type="primary"
                onClick={this.handleAddStore}
                className="buttonAddStore"
              >
                Add Store
              </Button>
            </div>
          </div>
          <Content
            className="content-section"
          >
            <Table
              columns={this.columns}
              dataSource={this.state.searchData}
              rowKey="key"
              className="table-container-store"
            />
          </Content>
        </Layout>

        <Drawer
          title={this.state.onChangeTitle ? "Edit Store" : "Add a Store"}
          width={720}
          onClose={this.handleCloseDrawer}
          open={this.state.storeDrawerOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button onClick={this.handleCloseDrawer}>Cancel</Button>
              <Button onClick={this.handleSubmit} type="primary">
                {this.state.onChangeTitle ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            requiredMark
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="storeName"
                  label="Store Name"
                  rules={[
                    { required: true, message: "Please enter store name" },
                  ]}
                >
                  <Input placeholder="Please enter store name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="addressLineOne"
                  label=" Store Address  1"
                  rules={[
                    { required: true, message: "Please enter Address Line 1" },
                  ]}
                >
                  <Input placeholder="Please enter address line 1" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="addressLineTwo"
                  label="Store Address 2"
                  rules={[
                    { required: true, message: "Please enter address line 2" },
                  ]}
                >
                  <Input placeholder="Please enter address line 2" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[{ required: true, message: "Please enter Pin Code" },
                  { pattern: /^[0-9]{6}$/, message: 'Pincode should be of 6 digits and in number' }]}
                >
                  <Input placeholder="Please enter Pin Code" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "Please enter city" }]}
                >
                  <Input placeholder="Please enter city" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: "Please enter state" }]}
                >
                  <Select
                    placeholder="Select a state"
                    value={this.state.state}
                  >
                    {this.state.stateList.map((state: any) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter country address" },
                  ]}
                >
                  <Select
                    placeholder="Select a country"
                    value={"India"}
                  >
                    <Option value="India">India</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="storeType"
                  label="Store Type"
                  rules={[
                    {
                      required: true, message: "Please select at least one store type",
                    },
                  ]}
                >
                  <Checkbox.Group style={{ width: "100%", marginLeft: "0px" }}>
                    <Checkbox
                      value={1}
                      onChange={(elem: any) => {
                        let storeData = [...this.state.storeType]; // Create a copy of the array
                        if (elem.target.checked) {
                          if (!storeData.includes(1)) {
                            storeData.push(1);
                          }
                        } else {
                          storeData = storeData.filter((data: number) => data !== 1); // Filter out 1
                        }
                        this.setState({ storeType: storeData }, () => {
                          console.log(this.state.storeType); // Log after state update
                        });
                      }}
                    >
                      In Stock Store
                    </Checkbox>

                    <Checkbox
                      value={2}
                      disabled={this.state.isCheckboxDisabled}
                      onChange={(elem: any) => {
                        let storeData = [...this.state.storeType]; // Create a copy of the array
                        if (elem.target.checked) {
                          if (!storeData.includes(2)) {
                            storeData.push(2);
                          }
                        } else {
                          storeData = storeData.filter((data: number) => data !== 2); // Filter out 2
                        }
                        this.setState({ storeType: storeData }, () => {
                          console.log(this.state.storeType); // Log after state update
                        });
                      }}
                    >
                      Reject Store
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  storesData: state.api.getStore?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getStoresState: state.api.getStore,
  addStoreState: state.api.addStore,
  editStoresState: state.api.editStore,
  deleteStoresState: state.api.deleteStore,
});

const mapDispatchToProps = (dispatch: any) => ({
  getStores: (payload: any) => dispatch(getStores(payload)),
  addStores: (payload: any) => dispatch(addStores(payload)),
  editStore: (payload: any) => dispatch(editStores(payload)),
  deleteStore: (payload: any) => dispatch(deleteStores(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoresManagement);

import React, { useState, useEffect } from "react";
import type { MenuProps, TableProps } from "antd"; 
import {
  Breadcrumb,
  Button,
  Cascader,
  Dropdown,
  Input,
  Layout, 
  Space,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import "./Document.css";

interface Option {
  value: string;
  label: string;
  children?: Option[];
}

interface DataType {
  key: string;
  quotationNo: string;
  companyName: string;
  enquiryNo: number;
  totalAmount: number;
  ocCreated: boolean;
  dealStatus: string;
  dealOwner: string;
  nextActionDate: string;
  tags: string;
}

const Documents: React.FC = () => {
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const options: Option[] = [
    { value: "zhejiang", label: "Zhejiang" },
    { value: "jiangsu", label: "Jiangsu" },
  ];

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Doc No.",
      dataIndex: "quotationNo",
      key: "quotationNo",
      render: (text) => <a>{text}</a>,
    },
    { title: "Company", dataIndex: "companyName", key: "companyName" },
    { title: "Enquiry No.", dataIndex: "enquiryNo", key: "enquiryNo" },
    { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "OC Created",
      dataIndex: "ocCreated",
      key: "ocCreated",
      render: (ocCreated) => (ocCreated ? "Yes" : "No"),
    },
    { title: "Deal Status", dataIndex: "dealStatus", key: "dealStatus" },
    { title: "Deal Owner", dataIndex: "dealOwner", key: "dealOwner" },
    {
      title: "Next Action Date",
      dataIndex: "nextActionDate",
      key: "nextActionDate",
    },
    { title: "Tags", dataIndex: "tags", key: "tags" },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    { title: "Creation Date", dataIndex: "creationDate", key: "creationDate" },
  ];

  const data: DataType[] = [
    {
      key: "1",
      quotationNo: "#QTN123456",
      companyName: "HAPPY SHOP",
      enquiryNo: 43343434,
      totalAmount: 5434343455,
      ocCreated: true,
      dealStatus: "Final",
      dealOwner: "Sandeep Sharma",
      nextActionDate: "31 Sep 2024",
      tags: "In Store",
    },
  ];

  const items: MenuProps["items"] = [
    { label: "Sales Enquiry", key: "1" },
    { label: "Sales Quotation", key: "2" },
    { label: "Order Confirmation", key: "3" },
    { label: "Delivery Challan", key: "4" },
    { label: "Invoice", key: "5" },
    { label: "Sales Return", key: "6" },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
    const clickedItem = items.find((item) => item?.key === e.key);
    if (clickedItem && "label" in clickedItem) {
      const label = clickedItem.label as string | undefined;
      if (label) {
        setModalTitle(label);
        setIsModalVisible(true);
      } else {
        setModalTitle("Default Title");
      }
    }

    if (e.key === "1") {
      navigate("/sales_enquiry");
    }
    if (e.key === "2") {
      navigate("/sales_quotation");
    }
    if (e.key === "3") {
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Layout style={{ padding: "0 24px 24px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Breadcrumb style={{ margin: "20px 0", flex: 1 }}>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Sales</Breadcrumb.Item>
          <Breadcrumb.Item>Documents</Breadcrumb.Item>
        </Breadcrumb>
        <div className="cascader-div">
          <div className="cascader-div-one">
            <Cascader
              options={options}
              placeholder="SQ"
              className="cascaderOne"
            />
            <Cascader
              options={options}
              placeholder="Sent"
              className="cascaderTwo"
            />
          </div>
          <div className="cascader-div-two">
            <Cascader
              options={options}
              placeholder="Pendings"
              className="cascaderOne"
            />
            <Cascader
              options={options}
              placeholder="Tags"
              className="cascaderTwo"
            />
          </div>
          <div className="cascader-div-three">
            <Input
              placeholder="Search Quotations"
              className="search-quotation"
            />
            <Space wrap>
              <Dropdown
                menu={menuProps}
                trigger={["click"]}
                className="space-docs"
              >
                <Button type="primary">CREATE DOCUMENT</Button>
              </Dropdown>
            </Space>
          </div>
        </div>
      </div>

      <Content
        className="content-section"
      >
        <Table columns={columns} dataSource={data} />
      </Content>
    </Layout>
  );
};

export default Documents;

import {
  Row,
  Col,
  Card,
  Tooltip,
  Input,
  Button,
  Upload,
  UploadProps,
  UploadFile,
} from "antd";
import {
  InfoCircleOutlined,
  UploadOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "./SalesQuotationDetails.css";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useRef, useState } from "react";

const { TextArea } = Input;

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<any>;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: `translate(${transform?.x}px, ${transform?.y}px)`,
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

const SalesQuotationDetails: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState(" ");

  const handleEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (isEditing && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isEditing]);

  const handleSave = () => {
    setIsEditing(false);
    setTimeout(() => {}, 5000);
  };

  const handleChange = (e: any) => {
    setContent(e.target.value);
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={12}>
          <Card
            className="custom-card no-header-border"
            title={
              <span>
                Logistic Details &nbsp;
                <Tooltip title="Logistic Details">
                  <InfoCircleOutlined className="info-icon" />
                </Tooltip>
              </span>
            }
          >
            <div className="logisticDetailsTextArea">
              <div className="card-text">Write logistic detail here</div>
              <TextArea
                rows={3}
                placeholder="Enter your logistic details here..."
                className="termCondition-textarea"
                // showCount // Getting height issues
                maxLength={100}
              />
              <Tooltip title={isEditing ? "Edit" : "Saved"}>
                <Button
                  className="edit-button"
                  icon={
                    isEditing ? (
                      <EditOutlined />
                    ) : (
                      <CheckOutlined style={{ color: "green" }} />
                    )
                  }
                  onClick={isEditing ? handleSave : handleEdit}
                />
              </Tooltip>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Card
            className="custom-card no-header-border"
            title={
              <span>
                Additional Details &nbsp;
                <Tooltip title="Additional Details">
                  <InfoCircleOutlined className="info-icon" />
                </Tooltip>
              </span>
            }
          >
            <div className="card-content">
              <div className="card-text">Write additional detail here</div>
              <TextArea
                rows={3}
                placeholder="Add additional details here"
                showCount
                maxLength={100}
              />
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Card
            className="custom-card no-header-border card-fixed-height"
            title={
              <span>
                Attachments &nbsp;
                <Tooltip title="Attachments">
                  <InfoCircleOutlined className="info-icon" />
                </Tooltip>
              </span>
            }
          >
            <div className="card-content">
              <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={fileList.map((i) => i.uid)}
                  strategy={verticalListSortingStrategy}
                >
                  <Upload
                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    fileList={fileList}
                    onChange={onChange}
                    itemRender={(originNode, file) => (
                      <DraggableUploadListItem
                        originNode={originNode}
                        file={file}
                      />
                    )}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </SortableContext>
              </DndContext>
              <div className="file-size-info">
                Recommended file size up to 5MB.
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Card
            className="custom-card no-header-border card-fixed-height"
            title={
              <span>
                Signature &nbsp;
                <Tooltip title="Signature">
                  <InfoCircleOutlined className="info-icon" />
                </Tooltip>
              </span>
            }
          >
            <div className="card-content">
              <Upload
                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                listType="picture"
                defaultFileList={fileList}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
              <div className="file-size-info">
                Recommended resolution is 200x60 with file size of 32KB.
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SalesQuotationDetails;

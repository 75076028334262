export const AppConstants = {
  APP_NAME: "EASEMARGIN",
  APP_API_URL: "https://testapi.easemargin.com/",
  API_METHOD: {
    GET: "GET",
    POST: "POST",
  },
  STORE_TYPE_MAPPING: {
    IN_STOCK: {
      ENTITY_ID: '1',
      DISPLAY_STRING: "In Stock",
    },
    REJECT: {
      ENTITY_ID: '2',
      DISPLAY_STRING: "Reject",
    },
  },
  ADDRESS_TYPE_MAPPING: {
    DELIVERY_ADDRESSES: {
      ENTITY_ID: 1,
      DISPLAY_STRING: "Delivery Address"
    },
    BILLING_ADDRESSES: {
      ENTITY_ID: 2,
      DISPLAY_STRING: "Billing Address"
    },
  },
  ITEM_TYPES: {
    BUY: {
      ITEM_TYPE: "Buy",
      ITEM_TYPE_ID: 1 
    },
    SELL: {
      ITEM_TYPE: "Sell",
      ITEM_TYPE_ID: 2 
    },
    BOTH: {
      ITEM_TYPE: "Both",
      ITEM_TYPE_ID: 3
    } 
  },
  ITEM_CATEGORIES: {
    RAW_MATERIALS: {
      ITEM_CATEGORY: "Raw Materials",
      ITEM_CATEGORY_ID: 1 
    },
    FINISHED_GOODS: {
      ITEM_CATEGORY: "Finished Goods",
      ITEM_CATEGORY_ID: 2 
    },
    CONSUMABLES: {
      ITEM_CATEGORY: "Consumables",
      ITEM_CATEGORY_ID: 3 
    } 
  },
  ITEM_METRICS: {
    KGS: {
      ITEM_METRIC: "KGs",
      ITEM_METRIC_ID: 1 
    },
    UNITS: {
      ITEM_METRIC: "Units",
      ITEM_METRIC_ID: 2 
    },
    METERS: {
      ITEM_METRIC: "Meters",
      ITEM_METRIC_ID: 3 
    } 
  },
  ITEM_TAX_TYPES: {
    INCLUSIVE: {
      ITEM_TAX: "Inclusive",
      ITEM_TAX_ID: 1 
    },
    EXCLUSIVE: {
      ITEM_TAX: "Exclusive",
      ITEM_TAX_ID: 2
    },
  },
  ADDRESS_TYPE: {
    DELIVERY: {
      ADDRESS_NAME: "Delivery Address",
      ADDRESS_ID: 1
    },
    BILLING: {
      ADDRESS_NAME: "Billing Address",
      ADDRESS_ID: 2 
    },
  },
  COMPANY_TYPE_MAPPING: {
    BUYER: {
      COMPANY_TYPE: "Buyer",
      COMPANY_TYPE_ID: 1 
    },
    SELLER: {
      COMPANY_TYPE: "Seller",
      COMPANY_TYPE_ID: 2 
    },
    BOTH: {
      COMPANY_TYPE: "Both",
      COMPANY_TYPE_ID: 3
    } 
  }
};

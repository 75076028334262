import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { useLoader } from "../Loader/LoadContext";
import "./BuyerSupplier.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { addBuyerSupplier, getBuyerSupplier } from "../../redux/actions/API/buyerSupplier";
import Loader from "../Loader/Loader";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import { AppConstants } from "../../Appconstants";

const { Option } = Select;

const openNotificationWithIcon = (message: string) => {
  notification.open({
    message: message,
    type: 'success',
    duration: 3,
  });
};
interface DrawerProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (data: any) => void;
  onEdit: (data: any) => void;
  dataToHandle: any;
  isDisable: boolean;
  isEditMode: boolean;
  addBuyerSupplier: Function,
  companyId: number,
  addBuyerSupplierState: IDataResponse,
  getBuyerSupplier: Function,
  getBuyerSupplierState: IDataResponse
}

interface Address {
  addressLine1: string;
  addressLine2?: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  addressType: string;
}

const AddEditBuyerSupplier: React.FC<DrawerProps> = ({
  visible,
  onClose,
  onAdd,
  onEdit,
  dataToHandle,
  isDisable,
  isEditMode,
  addBuyerSupplier,
  companyId,
  addBuyerSupplierState,
  getBuyerSupplier,
  getBuyerSupplierState
}) => {
  const prevPropsRef = useRef<any>();
  const [form] = Form.useForm();
  const [isGSTValid, setIsGSTValid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("Add Buyer/Supplier");
  const [addresses, setAddresses] = useState<any[]>([]);
  const [showLink, setshowLink] = useState(true);
  const [showBtns, setshowBtns] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState<number | null>(0);
  const [addressError, setAddressError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataToHandle) {
      setAddresses(dataToHandle.addresses);
      form.setFieldsValue(dataToHandle); // Prefill data
      if (isDisable) {
        setTitle("View Buyer/Supplier");
        setshowLink(false);
        setShowAddress(false);
        setshowBtns(false);
      }
      if (isEditMode) {
        setAddresses(dataToHandle.addresses);
        setIsEdit(true);
        setTitle("Edit Buyer/Supplier");
        setshowLink(true);
        setshowBtns(true);
        console.log("isEditMode triggered, showBtns set to:", true);
      }
    } else {
      setIsEdit(false);
      setTitle("Add Buyer/Supplier");
      form.resetFields();
      setshowLink(true);
    }
  }, [isEditMode, isDisable, dataToHandle, form]);

  useEffect(() => {
    setLoading(true);
    form.setFieldsValue({
      addressType: "BillingAddress",
    });
    getBuyerSupplier({
      companyId: companyId
    });
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.addBuyerSupplierState?.loading && !addBuyerSupplierState?.loading) {
      if (addBuyerSupplierState?.error?.length > 0) {

      } else {
        getBuyerSupplier({
          companyId: companyId
        })
        openNotificationWithIcon('Buyer/Supplier added successfully');
      }
    }

    if (prevPropsRef?.current?.getBuyerSupplierState?.loading && !getBuyerSupplierState?.loading) {
      if (getBuyerSupplierState?.error?.length > 0) {

      } else {
        setLoading(false);
      }
    }

    prevPropsRef.current = { addBuyerSupplier, addBuyerSupplierState, getBuyerSupplier, getBuyerSupplierState };
  }, [addBuyerSupplierState, getBuyerSupplierState]);

  const onFinish = (values: any) => {
    setLoading(true);
    if (isEdit) {
      onEdit({ ...dataToHandle, ...values }); 
    } else {
      console.log(values);
      console.log(addresses);
      let dataToSend = {
        companyId: companyId,
        companyEmail: values.companyEmail,
        companyName: values.companyName,
        companyType: values.companyType,
        email: values.email,
        gstNumber: values.GSTNumber,
        gstType: values.GSTType,
        name: values.name,
        phone: values.phone,
        ip_address: "127.0.0.1",
        addresses: addresses
      }
      console.log(dataToSend);
      addBuyerSupplier(dataToSend);
      setAddresses([]);
      form.resetFields();
    }
    form.setFieldsValue({ addressType: undefined });
    setShowAddress(false);
    setAddressError('false');
    onClose();
  };

  const handleGstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const gstNumber = e.target.value;
    setIsGSTValid(gstNumber.length === 15);
  };

  const handleAddMoreAddress = () => {
    const currentValues = form.getFieldsValue();

    setShowAddress(true);
    setShowSaveBtn(true);
    if (
      !currentValues.addressLine1 ||
      !currentValues.pincode ||
      !currentValues.city ||
      !currentValues.state ||
      !currentValues.country ||
      !currentValues.addressType
    ) {
      return;
    }
    setAddresses([
      ...addresses,
      {
        addressLine1: currentValues.addressLine1,
        addressLine2: currentValues.addressLine2,
        pincode: currentValues.pincode,
        city: currentValues.city,
        state: currentValues.state,
        country: currentValues.country,
        addressType: currentValues.addressType,
      },
    ]);
    clearAddressFields();
  };

  const clearAddressFields = () => {
    form.setFieldsValue({
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      addressType: undefined,
    });
  }

  const handleEditAddress = (index: number) => {
    console.log(`Edit Clicked on: ${index}`)
    // Get the current form values
    const currentValues = form.getFieldsValue();
    {
      // Save the current address if not empty
      const addressToSave = {
        addressLine1: currentValues.addressLine1,
        addressLine2: currentValues.addressLine2,
        pincode: currentValues.pincode,
        city: currentValues.city,
        state: currentValues.state,
        country: currentValues.country,
        addressType: currentValues.addressType,
      };
      // address has any filled fields
      if (
        addressToSave.addressLine1 ||
        addressToSave.addressLine2 ||
        addressToSave.pincode ||
        addressToSave.city ||
        addressToSave.state ||
        addressToSave.country ||
        addressToSave.addressType
      ) {
        const updatedAddresses = addresses?.map((address, i) =>
          i === index ? addressToSave : address
        );
        setAddresses(updatedAddresses);
      } else {
        const updatedAddresses = addresses.filter((_, i) => i !== index);
        setAddresses(updatedAddresses);
      }
      // Clear the form fields for new address input
      form.setFieldsValue({
        addressLine1: "",
        addressLine2: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        addressType: undefined,
      });
    }

    const addressToEdit = addresses[index];
    form.setFieldsValue({
      addressLine1: addressToEdit.addressLine1,
      addressLine2: addressToEdit.addressLine2,
      pincode: addressToEdit.pincode,
      city: addressToEdit.city,
      state: addressToEdit.state,
      country: addressToEdit.country,
      addressType: addressToEdit.addressType,
    });
    
  };

  const handleDeleteAddress = (index: number) => {
    console.log("Deleted");
    console.log(index);
    const updatedAddresses = addresses.filter((currEl, i) => i !== index);
    setAddresses(updatedAddresses);
    if (updatedAddresses.length === 0) {
      form.setFieldsValue({ addressType: undefined });
    }
  };

  const handleSaveAddress = () => {
    const currentValues = form.getFieldsValue();
    if (
      !currentValues.addressLine1 ||
      !currentValues.pincode ||
      !currentValues.city ||
      !currentValues.state ||
      !currentValues.country ||
      !currentValues.addressType
    ) {
      setAddressError('All required address fields must be filled')
      return;
    }
    setAddressError('');
    setShowAddress(false);
    setAddresses([
      ...addresses,
      {
        addressLine1: currentValues.addressLine1,
        addressLine2: currentValues.addressLine2,
        pincode: currentValues.pincode,
        city: currentValues.city,
        state: currentValues.state,
        country: currentValues.country,
        addressType: currentValues.addressType,
      },
    ]);

    form.setFieldsValue({
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      addressType: undefined,
    });
  }

  return (
    <>
      <Loader loading={loading}></Loader>
      <Drawer
        placement="right"
        title={title}
        onClose={() => {
          setAddressError('');
          setAddresses([]);
          setShowAddress(false);
          clearAddressFields();
          onClose();
        }}
        visible={visible}
        width={720}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setLoading(true);
                form.submit();
              }}
              disabled={!(addresses.length > 0)}
            >
              {isEdit ? "Update" : "Submit"}
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div style={{ marginBottom: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="formLabel">Person Contact Details</div>
              <div></div>
            </div>
          </div>
          <div
            style={{
              background: "#f0f0f0",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please enter a name" }]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: "Please enter an email" }]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    { required: true, message: "Please enter a phone number" },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="formLabel">Company Details</div>
              <div></div>
            </div>
          </div>
          <div
            style={{
              background: "#f0f0f0",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={[
                    { required: true, message: "Please enter a company name" },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="companyEmail"
                  label="Company Email"
                  rules={[
                    { required: true, message: "Please enter a company email" },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Form.Item
                name="companyType"
                label="Company Type"
                rules={[
                  { required: true, message: "Please enter a company type" },
                ]}
              >
                <Radio.Group
                  disabled={isDisable}
                  style={{ width: "100%", marginLeft: "px" }}
                >
                  <Row>
                    <Col span={8}>
                      <Radio value={1}>Buyer</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={2}>Supplier</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={3}>Both</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </div>
            <Row gutter={16} style={{ marginTop: 16 }}>
              <Col span={12}>
                <Form.Item
                  name="GSTNumber"
                  label="GST Number"
                  rules={[
                    { required: false, message: "Please enter a GST number" },
                  ]}
                >
                  <Input onChange={handleGstChange} disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="GSTType"
                  label="GST Type"
                  rules={[
                    { required: false, message: "Please select a GST type" },
                  ]}
                >
                  <Select disabled={isDisable} placeholder="Select GST Type">
                    <Option value={1}>Regular</Option>
                    <Option value={2}>Irregular</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {showAddress ? <div className="formLabel">Addresses</div> : <div></div>}
              {showLink && (
                <div className="addMore" onClick={showAddress ? (() => { }) : handleAddMoreAddress}>
                  {addresses.length > 0 ? '+ Add more address' : '+ Add Address'}
                </div>
              )}
            </div>
          </div>

          {addresses?.map((address: any, index: number) => (
            <div className="addressList" key={index}>
              <div className="addressHeader">
                <div className="addressType">
                  {Number(address.addressType) == AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID ? AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_NAME : AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_NAME}
                </div>
                <div className={index == 0 ? 'defaultType' : 'setAsdefaultType'}>{index == 0 ? 'Default' : 'Set as Default'}</div>
              </div>
              <div className="addressBody">
                <div>
                  {`${address.addressLine1 ? address.addressLine1 : address.addressLineOne}, ${address.addressLine2 ? `${address.addressLine2}, ` : address.addressLineTwo
                    }${address.city}, ${address.state}, ${address.pincode}, ${address.country
                    }`}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isEdit && (
                    <div>
                      <EditOutlined onClick={() => handleEditAddress(index)} />
                    </div>
                  )}
                  <div>
                    <DeleteOutlined
                      onClick={() => handleDeleteAddress(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          {showAddress && (
            <div
              style={{
                background: "#f0f0f0",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div className="error">{addressError}</div>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="addressLine1"
                    label="Address Line 1"
                    rules={[
                      { required: true, message: "Please enter an address" },
                    ]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="addressLine2" label="Address Line 2">
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="pincode"
                    label="Pin Code"
                    rules={[
                      { required: false, message: "Please enter a pincode" },
                    ]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: true, message: "Please enter a city" }]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[{ required: true, message: "Please enter a state" }]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[
                      { required: true, message: "Please enter a country" },
                    ]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="addressType"
                label="Address Type"
                rules={[{ required: true, message: "Please enter your country" }]}
              >
                <Radio.Group
                  disabled={isDisable}
                  style={{ width: "100%", marginLeft: "px" }}
                >
                  <Row>
                    <Col span={8}>
                      <Radio value={1}>Delivery Address</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={2}>Billing Address</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                  <Button type="link" onClick={handleSaveAddress}>Save Address</Button>
                </Form.Item>
                <Form.Item>
                  <Button type="text" onClick={() => {
                    clearAddressFields();
                    setShowAddress(false);
                    setAddressError('');
                  }}>Discard</Button>
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  addBuyerSupplierState: state.api.addBuyerSupplier,
  getBuyerSupplierState: state.api.getBuyerSupplier,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  addBuyerSupplier: (payload: any) => dispatch(addBuyerSupplier(payload)),
  getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBuyerSupplier);

import {
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Form,
  Layout,
  Breadcrumb,
  Button,
  Card,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SalesQuotation.css";
import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import CompanyMetaDataModal from "./CompanyMetaDataModal";
import { getItems } from "../../../redux/actions/API/items";
import { connect } from "react-redux";
import TableMetaData from "../table-meta-data/TableMetaData";
import SalesQuotationDetails from "./SalesQuotationDetails";
import TermsAndCondition from "./TermsAndCondition";
import "./SalesQuotation.css";

interface ISalesQuotationProps {
  getItems: Function;
  companyId: Number;
  userId: Number;
  itemsData: any;
}

const SalesQuotation: React.FC<ISalesQuotationProps> = ({
  getItems,
  companyId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCompanyData, setSelectedCompanyData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (companyId) {
      getItems({ companyId: Number(companyId) });
    }
  }, [companyId, getItems]);

  const onFinish = (values: object) => {
    console.log(values);
  };

  const handleOk = (companyData: any) => {
    setSelectedCompanyData(companyData);
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditing(false);
  };

  return (
    <>
      <CompanyMetaDataModal
        title="Sales Quotation"
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isFirstTimeEntry={!isEditing}
        isEditing={isEditing}
      />
      {selectedCompanyData && (
        <Layout style={{ padding: "0 24px 24px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Breadcrumb style={{ margin: "20px 0", flex: 1 }}>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Sales</Breadcrumb.Item>
              <Breadcrumb.Item>Documents</Breadcrumb.Item>
              <Breadcrumb.Item>Sales Quotation</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="divDocuments">
            <Link to="/documents" className="linkToDocs">
              <p className="textDocument">
                <ArrowLeftOutlined /> Back to Documents
              </p>
            </Link>

            <div className="divEditButton">
              <Button
                type="primary"
                onClick={() => {
                  setIsModalOpen(true);
                  setIsEditing(true);
                }}
                className="editCompanyButton"
              >
                Edit Company Details
              </Button>
            </div>
          </div>
          <div className="addressContainer">
            <Card className="infoCard">
              <div className="cardContent">
                <h5>EaseMargin PVT LTD</h5>
                <p className="cardParagraph">info@easemargin.com</p>
              </div>
            </Card>
            <Card className="addressCard billingAddressCard">
              <div className="cardContent">
                <h5>Billing Address</h5>
                <p className="cardParagraph">
                  {selectedCompanyData.selectedBillingAddress ? (
                    <div>
                      {`${
                        selectedCompanyData.selectedBillingAddress
                          .addressLineOne
                      }, ${
                        selectedCompanyData.selectedBillingAddress
                          .addressLineTwo
                          ? `${selectedCompanyData.selectedBillingAddress.addressLineTwo}, `
                          : ""
                      }${selectedCompanyData.selectedBillingAddress.city}, ${
                        selectedCompanyData.selectedBillingAddress.state
                      }, ${
                        selectedCompanyData.selectedBillingAddress.pincode
                      }, ${selectedCompanyData.selectedBillingAddress.country}`}
                    </div>
                  ) : (
                    <p>No billing address selected</p>
                  )}
                </p>
              </div>
            </Card>

            <Card className="addressCard deliveryAddressCard">
              <div className="cardContent">
                <h5>Delivery Address</h5>
                <p className="cardParagraph">
                  {selectedCompanyData.selectedDeliveryAddress ? (
                    <div>
                      {`${
                        selectedCompanyData.selectedDeliveryAddress
                          .addressLineOne
                      }, ${
                        selectedCompanyData.selectedDeliveryAddress
                          .addressLineTwo
                          ? `${selectedCompanyData.selectedDeliveryAddress.addressLineTwo}, `
                          : ""
                      }${selectedCompanyData.selectedDeliveryAddress.city}, ${
                        selectedCompanyData.selectedDeliveryAddress.state
                      }, ${
                        selectedCompanyData.selectedDeliveryAddress.pincode
                      }, ${
                        selectedCompanyData.selectedDeliveryAddress.country
                      }`}
                    </div>
                  ) : (
                    <p>No delivery address selected</p>
                  )}
                </p>
              </div>
            </Card>
          </div>

          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              minHeight: 280,
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Card className="cardForm">
              <Form layout="vertical" requiredMark onFinish={onFinish}>
                <Row className="formRow">
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Document Number&nbsp;
                          <Tooltip title="Document Number.">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="documentNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter document number",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter document number" />
                    </Form.Item>
                  </Col>
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Document Date&nbsp;
                          <Tooltip title="Document Date.">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="documentDate"
                      rules={[
                        {
                          required: true,
                          message: "Please choose the document date",
                        },
                      ]}
                    >
                      <DatePicker className="datePicker" />
                    </Form.Item>
                  </Col>
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Enquiry Number&nbsp;
                          <Tooltip title="Enquiry Number.">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="enquiryNumber"
                    >
                      <Input placeholder="Please enter enquiry number" />
                    </Form.Item>
                  </Col>
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Enquiry Date&nbsp;
                          <Tooltip title="Enquiry Date.">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="enquiryDate"
                    >
                      <DatePicker className="datePicker" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="formRow">
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Delivery Date &nbsp;
                          <Tooltip title="Delivery Date">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="deliveryDate"
                    >
                      <DatePicker className="datePicker" />
                    </Form.Item>
                  </Col>
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Store&nbsp;
                          <Tooltip title="Store">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="store"
                      rules={[
                        { required: true, message: "Please choose the store" },
                      ]}
                    >
                      <Select
                        placeholder="Please select the store"
                        options={[
                          { value: "store1", label: "Store 1" },
                          { value: "store2", label: "Store 2" },
                          { value: "store3", label: "Store 3" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="formItem">
                    <Form.Item
                      label={
                        <span>
                          Payment Terms&nbsp;
                          <Tooltip title="Payment Terms">
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </span>
                      }
                      name="paymentsTerms"
                    >
                      <Input placeholder="Please enter Payment Terms" />
                    </Form.Item>
                  </Col>
                  <Col className="formItem">
                    <Form.Item name="amendment" label="Amendment">
                      <Input placeholder="Please enter amendment" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>

            <div className="table-sales-quotation">
              <TableMetaData />
            </div>
            <div style={{ marginTop: "20px" }}>
              <SalesQuotationDetails />
            </div>
            <div style={{ marginTop: "20px" }}>
              <TermsAndCondition />
            </div>
          </Content>
        </Layout>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  getItemState: state.api.getItems,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesQuotation);

import {
    Avatar, Breadcrumb, Button, Col, Drawer, Form, Input, Layout, message, notification, Popconfirm, Row, Select, Space,
    Table, Upload, Image
} from 'antd';
import { UploadOutlined, PlusOutlined, EditOutlined, DeleteOutlined, MessageOutlined, OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useState, useRef, useEffect, useInsertionEffect } from 'react';
import '../../Utility/css/manageblogs.css'
import { connect } from 'react-redux';
import { addBlog, deleteBlog, editBlog, getBlogs } from '../../redux/actions/API/blogs';
import type { UploadProps, GetProp, UploadFile, } from 'antd';
import { uploadImage } from '../../redux/actions/API/imageUpload';
import './ManageBlogs.css';
import Loader from '../../Home/Loader/Loader';
import { IDataResponse } from '../../redux/types/API/ApiResponse';
import { AutoImage, ImageInsert, ImageResizeButtons, ImageResizeEditing, ImageResizeHandles, LinkImage, Table as table } from 'ckeditor5';
import {
    ClassicEditor, Context, Bold, Essentials, Italic, Paragraph, ContextWatchdog, Heading, Link,
    Image as image,
    ImageToolbar,
    ImageCaption,
    ImageStyle,
    ImageResize,
    List,
    Font,
    ListProperties,
    AutoLink,
} from 'ckeditor5';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import 'ckeditor5/ckeditor5.css';

type TinyMCEEditorInstance = {
    getContent: () => string;
};

interface IManageBlogsProps {
    getBlogs: Function;
    blogsData: any;
    userId: number;
    getBlogsState: IDataResponse;
    addBlogs: Function,
    addBlogsState: IDataResponse;
    editBlog: Function,
    editBlogState: IDataResponse;
    deleteBlog: Function,
    deleteBlogState: IDataResponse,
    blogId: number,
    uploadImage: Function
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const ManageBlogs: React.FC<IManageBlogsProps> = ({ getBlogs, blogsData, userId, getBlogsState, addBlogs, addBlogsState, editBlog, editBlogState, deleteBlog, deleteBlogState, blogId, uploadImage }) => {
    const prevPropsRef = useRef<IManageBlogsProps>();
    const [form] = Form.useForm();
    const [addBlogsOpen, setAddBlogsOpen] = useState(false);
    const [blogTitle, setBlogTitle] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [blogDescription, setBlogDescription] = useState('');
    const [author, setAuthor] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [loading, setLoading] = useState(true);
    const [stateBlogData, setStateBlogData] = useState(blogsData);
    const [isEditing, setIsEditing] = useState(false);
    const [currentBlogId, setCurrentBlogId] = useState<number | null>(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([])

    useEffect(() => {
        getBlogs();
    }, []);

    useEffect(() => {
        console.log(prevPropsRef);
        if (prevPropsRef?.current?.getBlogsState?.loading && !getBlogsState?.loading) {
            if (getBlogsState?.error?.length > 0) {

            } else {
                setLoading(false);
                console.log('removeloader');
            }
        }

        if (prevPropsRef?.current?.addBlogsState?.loading && !addBlogsState?.loading) {
            if (addBlogsState?.error?.length > 0) {

            } else {
                getBlogs();
                openNotificationWithIcon('success');
                setLoading(false)
            }
        }

        if (prevPropsRef?.current?.deleteBlogState?.loading && !deleteBlogState?.loading) {
            if (deleteBlogState?.error?.length > 0) { }
            else {
                getBlogs()
                openNotificationWithIconDelete('success')
            }
        }

        if (prevPropsRef?.current?.editBlogState?.loading && !editBlogState?.loading) {
            if (editBlogState?.error?.length > 0) { }
            else {
                getBlogs()
                openNotificationWithIconEdit('success')
            }
        }
        prevPropsRef.current = { uploadImage, getBlogs, blogsData, userId, getBlogsState, addBlogs, addBlogsState, editBlog, editBlogState, deleteBlog, deleteBlogState, blogId };
    }, [getBlogsState, addBlogsState, deleteBlogState, editBlogState]);

    useEffect(() => {
        form.resetFields();
    }, [addBlogsOpen]);

    useEffect(() => {
        setStateBlogData(blogsData);
    }, [blogsData]);

    const openNotificationWithIcon = (type: NotificationType) => {
        notification.open({
            message: 'Blog added Succesfully',
            type: type,
            duration: 3,
        });
    };

    const openNotificationWithIconEdit = (type: NotificationType) => {
        notification.open({
            message: 'Blog updated Succesfully',
            type: type,
            duration: 3,
        });
    };

    const openNotificationWithIconDelete = (type: NotificationType) => {
        notification.open({
            message: 'Blog deleted Succesfully',
            type: type,
            duration: 3,
        });
    };

    const renderBlogs = () => {
        return stateBlogData?.map((blog: any) => {
            const updatedAtDate = new Date(blog.createdAt).toLocaleDateString();
            return <div className='table-container'>
                <div className='blogBody'>
                    <div className='blogData'>
                        <div className='blogImage'>
                            <img src={blog.imageURL} alt="blog image" className='blogImage'></img>
                        </div>
                        <div className='blogText'>
                            <div className='blogTitle'>{blog.title}</div>
                            <div>{blog.shortDesc}</div>
                            <div>Author : {blog.author}</div>
                            <div>Uploaded date: {updatedAtDate}</div>
                        </div>
                    </div>
                    <div className='blogActions'>
                        <div
                            className="edit"
                            onClick={() => onEditBlog(blog, userId)}
                        >
                            Edit
                        </div>
                        <Popconfirm
                            title="Are you sure you want to delete this blog?"
                            onConfirm={() => onDeleteBlog(blog.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div
                                className="delete"
                            // onClick={() => onDeleteBlog(blog.id)}
                            >
                                Delete
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            </div>
        })
    }

    const onEditBlog = (blog: any, userId: number) => {
        setLoading(false)
        console.log("Editing blog:", blog);
        setIsEditing(true);
        setCurrentBlogId(blog.id);
        setBlogTitle(blog.title);
        setShortDesc(blog.shortDesc);
        setBlogDescription(blog.content);
        setAuthor(blog.author);
        setImageURL(blog.imageURL);

        form.setFieldsValue({
            blogTitle: blog.title,
            shortDesc: blog.shortDesc,
            blogDescription: blog.content,
            imageURL: blog.imageURL,
            author: blog.author,
            userId: userId
        });
        setAddBlogsOpen(true);
    };

    const onDeleteBlog = (blogId: number) => {
        deleteBlog({ id: blogId });
    };

    const handleSaveBlog = (status: string) => {
        if (!isEditing) {
            form.validateFields()
                .then(() => {
                    setLoading(true);
                    const blogData = {
                        blogId: currentBlogId,
                        title: blogTitle,
                        shortDesc: shortDesc,
                        content: blogDescription,
                        userId: userId,
                        author: author,
                        imageURL: imageURL
                    };

                    if (status === 'publish') {
                        addBlogs(blogData);
                    }

                    setLoading(false);
                    setAddBlogsOpen(false);
                    clearForm();
                })
                .catch(() => {
                    message.error('Please fill in all form fields.');
                });
        } else {
            setLoading(true);
            const blogData = {
                blogId: currentBlogId,
                title: blogTitle,
                shortDesc: shortDesc,
                content: blogDescription,
                userId: userId,
                author: author,
                imageURL: imageURL
            };

            if (status === 'publish') {
                editBlog(blogData);
            }

            setLoading(false);
            setAddBlogsOpen(false);
            clearForm();
        }
    };


    useEffect(() => {
        if (currentBlogId) {
            console.log("Currently editing blog with ID:", currentBlogId);
        }
    }, [currentBlogId]);


    const clearForm = () => {
        form.resetFields()
        setCurrentBlogId(null)
        setIsEditing(false)
        setBlogTitle('');
        setShortDesc('');
        setBlogDescription('');
        setAuthor('');
        setImageURL('');
    }

    const onSearch = (searchString: string) => {
        let filteredData = blogsData.filter((blog: any) => {
            return blog.title.toLowerCase().includes(searchString.toLowerCase()) ||
                blog.shortDesc.toLowerCase().includes(searchString.toLowerCase()) ||
                blog.author.toLowerCase().includes(searchString.toLowerCase());
        });
        setStateBlogData(filteredData);
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const handleUpload = (info: any) => {
        if (info.file.status === 'done') {
            // Assuming the URL of the uploaded image is available in `info.file.response.url`
            setImageURL(info.file.response.url);
        }
    };

    return (<>
        <Loader loading={loading}></Loader>
        <Layout
            className='layout-main-blogs'
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumb
                    style={{
                        margin: '16px 0',
                        flex: 1, // Take up remaining space
                    }}
                >
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item>Manage Blogs</Breadcrumb.Item>
                </Breadcrumb>

                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <Input placeholder="Search Blogs" style={{ marginRight: "20px" }} onChange={(e) => {
                        onSearch(e.target.value)
                    }} />
                    <Button type="primary" onClick={() => {
                        setAddBlogsOpen(true)
                    }} style={{ marginLeft: 'auto' }}>
                        Add Blog
                    </Button>
                </div>
            </div>
            <Content
                className='content-blog'
            >
                {renderBlogs()}
            </Content>
        </Layout>
        <Drawer
            placement="right"
            title={isEditing ? "Edit Blog" : "Add a Blog"}
            width={720}
            onClose={() => { setAddBlogsOpen(false) }}
            open={addBlogsOpen}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    <Button onClick={() => {
                        setAddBlogsOpen(false);
                        form.resetFields()
                    }}>Cancel</Button>
                    <Button type="primary" onClick={() => handleSaveBlog('publish')} className='button-publish'>
                        {isEditing ? "Update" : "Publish"}
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="blogTitle"
                            label="Blog title"
                            rules={[{ required: true, message: 'Please enter blog title' }]}
                        >
                            <Input placeholder="Please enter blog title" defaultValue={blogTitle ? blogTitle : ''} onChange={(e) => { setBlogTitle(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="shortDesc"
                            label="Short description"
                            rules={[{ required: true, message: 'Please enter short description' }]}
                        >
                            <Input placeholder="Please enter short description" defaultValue={shortDesc} onChange={(e) => { setShortDesc(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="blogDescription"
                            label="Blog Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter blog description',
                                },
                            ]}
                        >
                            {/* <Input.TextArea placeholder='Please enter blog description'
                             value={blogDescription} onChange={(e) => { setBlogDescription(e.target.value) }} /> */}
                            <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        plugins: [
                                            Essentials,
                                            Bold,
                                            Italic,
                                            Paragraph,
                                            Heading,
                                            Link,
                                            image,
                                            ImageToolbar,
                                            ImageCaption,
                                            ImageStyle,
                                            ImageResize,
                                            ImageInsert,
                                            LinkImage,
                                            ImageResizeEditing,
                                            ImageResizeButtons,
                                            ImageResizeHandles,
                                            AutoImage,
                                            List,
                                            Font,
                                            ListProperties,
                                            AutoLink,
                                        ],
                                        toolbar: {
                                            items: [
                                                'heading',
                                                '|',
                                                'fontSize',
                                                'fontFamily',
                                                // 'fontColor',
                                                'bold',
                                                'italic',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                'insertImage',
                                                '|',
                                                // 'resizeImage:50',
                                                // 'resizeImage:75',
                                                // 'resizeImage:original',
                                                // 'resizeImage:custom',
                                                '|',
                                                'undo',
                                                'redo',
                                            ],
                                            shouldNotGroupWhenFull: true
                                        },
                                        image: {
                                            toolbar: [
                                                'imageTextAlternative',
                                                'imageStyle:full',
                                                'imageStyle:side',
                                                'toggleImageCaption',
                                                'imageTextAlternative',
                                                'ckboxImageEdit',
                                                'resizeImage:50',
                                                'resizeImage:75',
                                                'resizeImage:original',
                                                'resizeImage:custom',
                                            ],
                                            resizeUnit: 'px',
                                            insert: {
                                                integrations: ['upload', 'assetManager', 'url']
                                            },
                                            resizeOptions: [
                                                {
                                                    name: 'resizeImage:original',
                                                    value: null,
                                                    icon: 'original'
                                                },
                                                {
                                                    name: 'resizeImage:custom',
                                                    value: 'custom',
                                                    icon: 'custom'
                                                },
                                                {
                                                    name: 'resizeImage:50',
                                                    value: '50',
                                                    icon: 'medium'
                                                },
                                                {
                                                    name: 'resizeImage:75',
                                                    value: '75',
                                                    icon: 'large'
                                                }
                                            ]
                                        },
                                        list: {
                                            properties: {
                                                styles: true,
                                                startIndex: true,
                                                reversed: true
                                            }
                                        }
                                        ,
                                        heading: {
                                            options: [
                                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                            ]
                                        },
                                        fontSize: {
                                            options: [
                                                'default',
                                                12,
                                                14,
                                                18,
                                                24,
                                                30,
                                                36,
                                                48,
                                            ],
                                        },
                                        fontFamily: {
                                            options: [
                                                'default',
                                                'Arial, Helvetica, sans-serif',
                                                'Courier New, Courier, monospace',
                                                'Georgia, serif',
                                                'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                                'Tahoma, Geneva, sans-serif',
                                                'Times New Roman, Times, serif',
                                                'Trebuchet MS, Helvetica, sans-serif',
                                                'Verdana, Geneva, sans-serif'
                                            ]
                                        },
                                        link: {
                                            addTargetToExternalLinks: true,
                                            decorators: {
                                                openInNewTab: {
                                                    mode: 'manual',
                                                    label: 'Open in a new tab',
                                                    attributes: {
                                                        target: '_blank',
                                                        rel: 'noopener noreferrer'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    data={blogDescription}
                                    onReady={(editor: any) => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData();
                                        console.log('editor data', data)
                                        setBlogDescription(data);
                                        form.setFieldsValue({ blogDescription: data })

                                        const content = editor.editing.view.document.getRoot();
                                        for (const child of content.getChildren()) {
                                            if (child.is('element', 'heading1') || child.is('element', 'heading2')) {
                                                // Perform model-related actions or leave this block empty
                                                console.log(`Heading detected: ${child.name}`);
                                            }
                                        }
                                    }}
                                />
                            </CKEditorContext>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="imageURL"
                            label="Image URL"
                            rules={[{ required: true, message: 'Please enter image URL' }]}
                        >
                            <Input placeholder="Please enter imageURL" defaultValue={imageURL} onChange={(e) => { setImageURL(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="imageURL"
                        //rules={[{ required: true, message: 'Please select an image to Upload' }]}
                        >
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 8 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{ display: 'none' }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="author"
                            label="Author"
                            rules={[{ required: true, message: 'Please enter your name' },
                             { pattern: /^[A-Za-z\s]+$/, message: 'Author name can only contain alphabets and spaces' }]}
                        >
                            <Input placeholder="Please enter your name" defaultValue={author}
                                onChange={(e) => { setAuthor(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    </>);
}

const mapStateToProps = (state: any) => ({
    blogsData: state.api.getBlogs?.data?.reverse(),
    userId: state.api.login.data?.id,
    getBlogsState: state.api.getBlogs,
    addBlogsState: state.api.addBlog,
    editBlogState: state.api.editBlog,
    deleteBlogState: state.api.deleteBlog,
    blogId: state.api.getBlogs.data?.id
});

const mapDispatchToProps = (dispatch: any) => ({
    addBlogs: (payload: any) => dispatch(addBlog(payload)),
    getBlogs: (payload: any) => dispatch(getBlogs(payload)),
    uploadImage: (payload: FormData) => dispatch(uploadImage(payload)),
    editBlog: (payload: any) => dispatch(editBlog(payload)),
    deleteBlog: (payload: any) => dispatch(deleteBlog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBlogs);
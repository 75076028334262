import {
    Col,
    DatePicker,
    Input,
    Row,
    Select,
    Form,
    Layout,
    Breadcrumb,
    Button,
    Card,
    Tooltip,
    Modal,
} from "antd";
import { ArrowLeftOutlined, InfoCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import CompanyMetaDataModal from "../CompanyMetaDataModal";
import moment from "moment"
import { getItems } from "../../../redux/actions/API/items";
import { connect } from "react-redux";
import TableMetaData from "../table-meta-data/TableMetaData";
import './SalesEnquiry.css'
import { getStores } from "../../../redux/actions/API/storesActions";
import { IDataResponse } from "../../../redux/types/API/ApiResponse"; 
import TermsAndCondition from "../sales-quotation/TermsAndCondition";
import SeriesMetaDataModal from "./SeriesMetaDataModal";
import CustomMetaDataModal from "../CustomMetaDataModal";
import "../../Components/sales-quotation/TermsAndCondition.css"

interface SalesEnquiryProps {
    getItems: Function,
    companyId: Number,
    userId: Number,
    itemsData: any,
    getStores: Function,
    storesData: any,
    getStoresState: IDataResponse
}

interface CustomField {
    key: string;
    label: string;
    fieldType: "text" | "integer" | "select" | "datetime";
    options?: string[];
    defaultValue: any
}

const SalesEnquiry: React.FC<SalesEnquiryProps> = ({ getItems, companyId, userId, itemsData, getStores, storesData }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedCompanyData, setSelectedCompanyData] = useState<any>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [newFieldType, setNewFieldType] = useState<"text" | "integer" | "select" | "datetime">("text");
    const [newFieldLabel, setNewFieldLabel] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFieldOptions, setNewFieldOptions] = useState("");
    const [defaultValue, setDefaultValue] = useState("");
    const [category, setCategory] = useState<string>("Sales Enquiry");
    const [mode, setMode] = useState<"form" | "table">("form");
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [documentNumber, setDocumentNumber] = useState<string>("");
    const [isEditingIndex, setIsEditingIndex] = useState<number>();
    const [form] = Form.useForm();
    useEffect(() => {
        if (documentNumber) {
            form.setFieldsValue({ documentNumber });
        }
    }, [documentNumber, form]);

    useEffect(() => {
        const savedFieldsKey = `customFields_${mode}_${category}`;
        const savedFields = localStorage.getItem(savedFieldsKey);
        if (savedFields) {
            setCustomFields(JSON.parse(savedFields));
        }
    }, [category, mode]);

    const handleSaveCustomFields = (fields: CustomField[]) => {
        setCustomFields(fields);
        const savedFieldsKey = `customFields_${mode}_${category}`;
        localStorage.setItem(savedFieldsKey, JSON.stringify(fields));
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (companyId) {
            getStores({ companyId: Number(companyId) });
        }
    }, [companyId, getStores]);

    const onFinish = (values: object) => {
        console.log(values);
    };

    const handleOk = (companyData: any) => {
        setSelectedCompanyData(companyData);
        setIsModalOpen(false);
        setIsEditing(false);
    };

    useEffect(() => {
        setIsModalOpen(true);
    }, []);

    const showSeriesModal = () => {
        setIsVisible(true);
    };

    const handleSeriesModalClose = () => {
        setIsVisible(false);
    };

    const handleSeriesSelection = (selectedSeries: string) => {
        console.log("Received Series in SalesEnquiry:", selectedSeries);
        setDocumentNumber(selectedSeries);
        handleSeriesModalClose();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEditing(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const renderCustomField = (field: CustomField) => {
        switch (field.fieldType) {
            case "text":
                return <Input readOnly placeholder={`Please enter ${field.label.toLowerCase()}`} defaultValue={field.defaultValue as string} />;
            case "integer":
                return <Input readOnly type="number" placeholder={`Please enter ${field.label.toLowerCase()}`} defaultValue={field.defaultValue as number} />;
            case "select":
                return (
                    <Select placeholder={`Please select ${field.label.toLowerCase()}`} defaultValue={field.defaultValue as string}>
                        {field.options?.map((option: string, index: number) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "datetime":
                return <DatePicker
                    readOnly format="YYYY-MM-DD"
                    className="datePicker"
                    defaultValue={field.defaultValue ? moment(field.defaultValue) : undefined}
                />;
            default:
                return <Input readOnly placeholder={`Please enter ${field.label.toLowerCase()}`} defaultValue={field.defaultValue as string} />;
        }
    };

    const storeOptions = storesData?.map((store: any) => ({
        value: store.id,
        label: store.name,
    }));

    return (
        <>
            <CompanyMetaDataModal
                title="Sales Enquiry"
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                isFirstTimeEntry={!isEditing}
                isEditing={isEditing}
            />
            {selectedCompanyData && (
                <Layout style={{ padding: "0 24px 24px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Breadcrumb style={{ margin: "20px 0", flex: 1 }}>
                            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item>Sales</Breadcrumb.Item>
                            <Breadcrumb.Item>Documents</Breadcrumb.Item>
                            <Breadcrumb.Item>Sales Enquiry</Breadcrumb.Item>
                        </Breadcrumb>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "flex-start",
                            }}
                        ></div>
                    </div>

                    <div className="divDocuments">
                        <Link to="/documents" className="linkToDocs">
                            <p className="textDocument"><ArrowLeftOutlined /> Back to Documents</p>
                        </Link>

                        <div className="divEditButton">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setIsEditing(true);
                                }}
                                className="editCompanyButton"
                            >
                                Edit Company Details
                            </Button>
                        </div>
                    </div>

                    <div className="divCardAddress">
                        <Card className="cardEaseMarginInfo">
                            <div className="divTextCard">
                                <h5>EaseMargin PVT LTD</h5>
                                <p className="paraghTextCard">info@easemargin.com</p>
                            </div>
                        </Card>
                        <Card className="cardBillingAddress">
                            <div className="divTextCard">
                                <h5>Billing Address</h5>
                                <p className="paraghTextCard">
                                    {selectedCompanyData.selectedBillingAddress ? (
                                        <div>
                                            {`${selectedCompanyData.selectedBillingAddress.addressLineOne}, ${selectedCompanyData.selectedBillingAddress.addressLineTwo ? `${selectedCompanyData.selectedBillingAddress.addressLineTwo}, ` : ''
                                                }${selectedCompanyData.selectedBillingAddress.city}, ${selectedCompanyData.selectedBillingAddress.state}, ${selectedCompanyData.selectedBillingAddress.pincode}, ${selectedCompanyData.selectedBillingAddress.country}`}
                                        </div>
                                    ) : (
                                        <p>No billing address selected</p>
                                    )}
                                </p>
                            </div>
                        </Card>
                        <Card className="cardDeliveryAddress">
                            <div className="divTextCard">
                                <h5>Delivery Address</h5>
                                <p className="paraghTextCard">
                                    {selectedCompanyData.selectedDeliveryAddress ? (
                                        <div>
                                            {`${selectedCompanyData.selectedDeliveryAddress.addressLineOne}, ${selectedCompanyData.selectedDeliveryAddress.addressLineTwo ? `${selectedCompanyData.selectedDeliveryAddress.addressLineTwo}, ` : ''
                                                }${selectedCompanyData.selectedDeliveryAddress.city}, ${selectedCompanyData.selectedDeliveryAddress.state}, ${selectedCompanyData.selectedDeliveryAddress.pincode}, ${selectedCompanyData.selectedDeliveryAddress.country}`}
                                        </div>
                                    ) : (
                                        <p>No delivery address selected</p>
                                    )}
                                </p>
                            </div>
                        </Card>
                    </div>


                    <Content
                        style={{
                            paddingTop: 10,
                            margin: 0,
                            paddingLeft: 0,
                            minHeight: 280,
                            background: "#f5f5f5",
                            borderRadius: "10px",
                        }}
                    >
                        <Card className="cardForm">
                            <Form layout="vertical" form={form} requiredMark onFinish={onFinish}>
                                <Row justify="end">
                                    <Col>
                                        <Button type="default" onClick={openModal}>
                                            Add Custom Field
                                        </Button>
                                    </Col>
                                </Row>
                                <Row gutter={[20, 20]} className="rowOne">
                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="documentNumber"
                                            label={
                                                <Row gutter={8} align="middle">
                                                    <Col>
                                                        <span>
                                                            Document Number&nbsp;
                                                            <Tooltip title="Document Number.">
                                                                <InfoCircleOutlined className="info-icon" />
                                                            </Tooltip>
                                                        </span>
                                                        <Col>
                                                            <Button className="buttonSeries" type="primary" onClick={showSeriesModal}>
                                                                Series
                                                            </Button>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter document number",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter Document number" value={documentNumber}
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <SeriesMetaDataModal
                                        isVisible={isVisible}
                                        onClose={handleSeriesModalClose}
                                        onSelectSeries={handleSeriesSelection}
                                        setIsVisible={setIsVisible}
                                    />

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="documentDate"
                                            label={
                                                <span>
                                                    Document Date&nbsp;
                                                    <Tooltip title="Document Date.">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                            initialValue={moment()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please choose the document date",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                className="datePicker"
                                                disabled
                                                defaultValue={moment()}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="amendment"
                                            label={
                                                <span>
                                                    Amendment&nbsp;
                                                    <Tooltip title="Amendment">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <Input placeholder="Please enter amendment" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="deliveryDate"
                                            label={
                                                <span>
                                                    Delivery Date&nbsp;
                                                    <Tooltip title="Delivery Date">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <DatePicker className="datePicker" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="paymentTerm"
                                            label={
                                                <span>
                                                    Payment Term&nbsp;
                                                    <Tooltip title="Payment Term">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <Select
                                                placeholder="Please select the payment team"
                                                options={[
                                                    { value: "PaymentTeam1", label: "Payment Team 1" },
                                                    { value: "PaymentTeam2", label: "Payment Team 2" },
                                                    { value: "PaymentTeam3", label: "Payment Team 3" },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="store"
                                            label={
                                                <span>
                                                    Store&nbsp;
                                                    <Tooltip title="Store">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                            rules={[
                                                { required: true, message: "Please choose the store" },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Please select the store"
                                                options={storeOptions}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[20, 20]}>
                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="customerEnquiryNumber"
                                            label={
                                                <span>
                                                    Enquiry Number&nbsp;
                                                    <Tooltip title="Enquiry Number">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <Input placeholder="Please enter enquiry number" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="customerEnquiryDate"
                                            label={
                                                <span>
                                                    Enquiry Date&nbsp;
                                                    <Tooltip title="Enquiry Date">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <DatePicker className="datePicker" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="pocName"
                                            label={
                                                <span>
                                                    POC Name&nbsp;
                                                    <Tooltip title="POC Name">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <Input placeholder="Please enter POC name" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="pocContactNumber"
                                            label={
                                                <span>
                                                    POC Contact Number&nbsp;
                                                    <Tooltip title="POC Contact Number">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <Input placeholder="Please enter POC Contact Number" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="expectedReplyDate"
                                            label={
                                                <span>
                                                    Expected Reply Date&nbsp;
                                                    <Tooltip title="Expected Reply Date">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <DatePicker className="datePicker" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item
                                            name="kindAttention"
                                            label={
                                                <span>
                                                    Kind Attention&nbsp;
                                                    <Tooltip title="Kind Attention">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            <Input placeholder="Please enter the description" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[20, 20]}>
                                    {customFields.map((field, map) => (
                                        <Col key={field.key} xs={24} sm={12} md={4}>
                                            <Form.Item
                                                name={field.key}
                                                label={
                                                    <span>
                                                        {field.label}&nbsp;
                                                        <Tooltip title={field.label}>
                                                            <InfoCircleOutlined className="info-icon" />
                                                        </Tooltip>
                                                    </span>
                                                }
                                            >
                                                {renderCustomField(field)}
                                            </Form.Item>
                                        </Col>
                                    ))}
                                </Row>
                            </Form>

                            <CustomMetaDataModal
                                mode="form"
                                customField={customFields}
                                isVisible={isModalVisible}
                                onSave={handleSaveCustomFields}
                                onClose={closeModal} />
                        </Card>

                        <div className="table-sales-enquiry">
                            <TableMetaData />
                        </div>

                        <div className="divTerms">
                            <TermsAndCondition />
                        </div>
                    </Content>
                </Layout>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    itemsData: state.api.getItems?.data?.reverse(),
    getItemState: state.api.getItems,
    userId: state.api.login.data.id,
    companyId: state.api.login.data.companyId,
    storesData: state.api.getStore?.data?.reverse(),
    getStoresState: state.api.getStore,
});

const mapDispatchToProps = (dispatch: any) => ({
    getItems: (payload: any) => dispatch(getItems(payload)),
    getStores: (payload: any) => dispatch(getStores(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEnquiry);

import { Button, Checkbox, Col, Form, Modal, Radio, Row, Select } from "antd";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import React, { useEffect, useState } from "react";
import { getBuyerSupplier } from "../../redux/actions/API/buyerSupplier";
import { connect } from "react-redux";
import { AppConstants } from "../../Appconstants";
import { useNavigate } from 'react-router-dom';
import './CompanyMetaDataModel.css'

interface CompanyMetaDataModalProps {
    title: string;
    isModalOpen: boolean;
    handleOk: (companyData: any) => void;
    isFirstTimeEntry: boolean;
    handleCancel: () => void;
    buyerSupplierData: any,
    getBuyerSupplier: Function,
    companyId: number,
    isEditing: boolean;
}

const CompanyMetaDataModal: React.FC<CompanyMetaDataModalProps> = ({ title, isModalOpen, handleOk, handleCancel, companyId,
    getBuyerSupplier,
    buyerSupplierData,
    isFirstTimeEntry,
    isEditing
}) => {

    const navigate = useNavigate();
    const [selectedCompany, setSelectedCompany] = useState<any>(null);
    const [selectedAddresses, setSelectedAddresses] = useState<number[]>([]);
    const [defaultAddressId, setDefaultAddressId] = useState<number | null>(null);
    const [defaultDeliveryAddressId, setDefaultDeliveryAddressId] = useState<number | null>(null);
    const [defaultBillingAddressId, setDefaultBillingAddressId] = useState<number | null>(null);


    useEffect(() => {
        getBuyerSupplier({ companyId: Number(companyId) });
    }, [companyId, getBuyerSupplier]);

    useEffect(() => {
        if (selectedCompany) {
            const deliveryAddresses = selectedCompany.addresses.filter(
                (address: any) => address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
            );
            const billingAddresses = selectedCompany.addresses.filter(
                (address: any) => address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
            );
    
            // Set the existing default delivery address
            if (deliveryAddresses.length > 0) {
                setDefaultDeliveryAddressId(deliveryAddresses[0].id);
            }
    
            // Set the existing default billing address or fallback to the first delivery address
            if (billingAddresses.length > 0) {
                setDefaultBillingAddressId(billingAddresses[0].id);
            } else if (deliveryAddresses.length > 0) {
                setDefaultBillingAddressId(deliveryAddresses[0].id);
            }
        }
    }, [selectedCompany]);
    
    const handleCompanyChange = (value: string) => {
        const selected = buyerSupplierData?.find((item: any) => item.companyName === value);
        setSelectedCompany(selected || null);
        setSelectedAddresses([]);
        setDefaultDeliveryAddressId(null);
        setDefaultBillingAddressId(null);
    }
    const handleCancelClick = () => {
        handleCancel();
        if (isFirstTimeEntry) {
            navigate('/documents');
        }
    };


    const getAddressTypeName = (addressTypeId: number) => {
        return addressTypeId === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
            ? AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_NAME
            : AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_NAME;
    };

    const handleModalOk = (companyData: any) => {
        if (selectedCompany) {
            const selectedDeliveryAddress = selectedCompany.addresses.find(
                (address: any) => address.id === defaultDeliveryAddressId
            );
            const selectedBillingAddress = selectedCompany.addresses.find(
                (address: any) => address.id === defaultBillingAddressId
            );
            handleOk({
                companyName: selectedCompany.companyName,
                defaultDeliveryAddressId,
                defaultBillingAddressId,
                selectedDeliveryAddress,
                selectedBillingAddress,
            });
        }
    };

    const handleSetDefault = (addressId: number, addressType: number) => {
        if (addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID) {
            setDefaultDeliveryAddressId(addressId);
        } else if (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID) {
            setDefaultBillingAddressId(addressId);
        }
    };

    const renderAddress = (address: any, index: number, addressType: number) => (
        <div key={index} className="addressList">
            <div className="addressHeader">
                <div className="addressType">
                    {getAddressTypeName(address.addressType)}
                </div>
                {/* <div className={`defaultStatus ${address.id === defaultAddressId ? 'Default' : 'is-not-default'}`}>
                    {address.id === defaultAddressId ? 'Default' : 'Set as Default'}
                </div> */}
                <div className={`defaultStatus ${address.id === (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID ? defaultBillingAddressId : defaultDeliveryAddressId) ? 'Default' : 'is-not-default'}`}>
                    {address.id === (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID ? defaultBillingAddressId : defaultDeliveryAddressId) ? 'Default' : 'Set as Default'}
                </div>
                <div className="addressRadio">
                <Radio
                        checked={address.id === (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID ? defaultBillingAddressId : defaultDeliveryAddressId)}
                        onChange={() => handleSetDefault(address.id, addressType)}
                    />
                </div>
            </div>
            <div className="addressDetails">
                <div>
                    {`${address.addressLine1 ? address.addressLine1 : address.addressLineOne} ${address.addressLine2 ? address.addressLine2 : address.addressLineTwo} ${address.city} ${address.state} ${address.pincode} ${address.country}`}
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Modal
                title={title}
                open={isModalOpen}
                // onOk={handleModalOk}
                onCancel={handleCancelClick}
                footer={[
                    <Button key="cancel" onClick={handleCancelClick}>
                        Cancel
                    </Button>,    
                    <Button type="primary" key="ok" disabled={!selectedCompany || !defaultDeliveryAddressId || !defaultBillingAddressId} onClick={handleModalOk}>
                        OK
                    </Button>
                ]}
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="company"
                                label="Company"
                                rules={[
                                    { required: true, message: "Please choose the company" },
                                ]}
                            >
                                <Select placeholder="Please choose the company" onChange={handleCompanyChange}>
                                    {buyerSupplierData?.map((item: any) => (
                                        <Select.Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}></Col> */}
                    </Row>
                </Form>

                {/* {selectedCompany ? (
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Delivery Address" key="1">
                            {selectedCompany.addresses?.some((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID) ? (
                                selectedCompany.addresses
                                    .filter((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID)
                                    .map(renderAddress)
                            )
                                : (
                                    <div className="addressList">No delivery addresses available.</div>
                                )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Billing Address" key="2">
                            {selectedCompany.addresses?.some((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID) ? (
                                selectedCompany.addresses
                                    .filter((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID)
                                    .map(renderAddress)
                            ) : (
                                <div className="addressList">No billing addresses available.</div>
                            )}
                        </Tabs.TabPane>
                    </Tabs>
                ) : (
                    <div>No company selected yet.</div>
                )} */}
                {selectedCompany ? (
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Delivery Address" key="1">
                        {selectedCompany.addresses?.some((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID) ? (
                            selectedCompany.addresses
                                .filter((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID)
                                .map((address: any, index: number) => renderAddress(address, index, AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID))
                        ) : (
                            <div className="addressList">No delivery addresses available.</div>
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Billing Address" key="2">
                        {selectedCompany.addresses?.some((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID) ? (
                            selectedCompany.addresses
                                .filter((address: any) => address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID)
                                .map((address: any, index: number) => renderAddress(address, index, AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID))
                        ) : (
                            <div className="addressList">No billing addresses available.</div>
                        )}
                    </Tabs.TabPane>
                </Tabs>
            ) : (
                <div>No company selected yet.</div>
            )}
            </Modal>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    buyerSupplierData: state.api.getBuyerSupplier?.data?.reverse(),
    getBuyerSupplierState: state.api.getBuyerSupplier,
    companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
    getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMetaDataModal);

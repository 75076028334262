import * as type from '../types/API/itemTypes'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
}

export const itemsReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_ITEMS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_ITEMS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addItemsReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_ITEM:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.ADD_ITEM_SUCCESS:
            return {
                error: null,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const editItemsReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.EDIT_ITEM:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.EDIT_ITEM_SUCCESS:
            return {
                error: null,
                loading: false,
                data: action.payload.responseData
            }
        case type.EDIT_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const deleteItemsReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_ITEM:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.DELETE_ITEM_SUCCESS:
            return {
                error: null,
                loading: false,
                data: action.payload.responseData
            }
        case type.DELETE_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLoader } from '../Loader/LoadContext'
import { Button, Checkbox, Form, Grid, Input, message, theme, Typography } from "antd";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { setAppLocation } from '../../redux/actions/UI/appLocation';
import { loginUser } from '../../redux/actions/API/login';
import ease from '../../Utility/images/ease/svgfile/ease-primary.svg';
import { connect } from 'react-redux';
import './Signin.css';
import Loader from '../Loader/Loader';
import Header from './Header';
import { signupUser } from '../../redux/actions/API/signup';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export interface ISignInProps {
    loginUser: Function,
    loginData: any,
    signupUser: Function,
    signupData: any
}

const Signin: React.FC<ISignInProps> = ({ loginUser, loginData, signupUser, signupData }) => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('');
    const [name, setName] = useState('')
    const [companyName, setCompanyName] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loginData?.data?.token) {
            navigate('/dashboard');
        } else {
            if (loginData?.error) {
                setHasError(true);
                setLoading(false);
            } else {
                setHasError(false)
            }
        }
    }, [loginData]);

    useEffect(() => {
        if (signupData?.success) {
            message.success('Signup successful! Redirecting to login...')
            setTimeout(() => {
                setIsSignUp(false)
            }, 1500)
        } else if (signupData?.error) {
            message.error('Signup failed! Please try again')
        }
    }, [signupData])

    useEffect(() => {
        setLoading(false);
    }, [])

    const handleSignIn = (e: React.FormEvent) => {
        setHasError(false);
        setLoading(true);
        loginUser({
            email: username,
            password: password
        });
    }

    const screens = useBreakpoint();

    const onFinish = (values: any) => {
        console.log("Received values of form: ", values);
    };

    const handleSignUp = (e: React.FormEvent) => {
        e.preventDefault()
        setHasError(false)
        setLoading(true)
        const signupPayload = {
            name,
            username,
            email,
            companyName,
            password
        }
        if (name && username && email && companyName && password) {
            signupUser(signupPayload);
            message.success('User signed up successfully')
            setIsSignUp(false)
            setLoading(false)
        } 
        else {
            message.error('Please fill in all required fields!');
            setLoading(false);
        }
    }

    const loginForm = () => {
        return (<>
            <Loader loading={loading}></Loader>
            {screens.xs && <Header />}
            {screens.xs && <h4 className='loginHeading'>Welcome to Ease Margin</h4>}
            <h2 className='loginTitle'>Login</h2><div className='formBody'>
                {hasError && <div className="loginError">Invalid Credentials !</div>}
                <Form
                    name="normal_login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark="optional"

                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: "Please input your username!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <a href="">
                            Forgot password?
                        </a>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block={true} type="primary" onClick={handleSignIn}>
                            Log in
                        </Button>
                        <div className='loginText'>
                            <Text>Don't have an account?</Text>
                            <span className='link' onClick={() => {
                                setIsSignUp(true);
                                setHasError(false)
                            }}> Sign up now</span>
                        </div>
                    </Form.Item>
                </Form>
            </div></>);
    }

    const signUpForm = () => {
        return (<>
            {screens.xs && <Header />}
            {screens.xs && <h4 className='loginHeading'>Welcome to Ease Margin</h4>}
            <h2 className='loginTitle'>Sign Up</h2>
            <div className='formBody'>
                <Form name="normal_signup" onFinish={onFinish} layout="vertical" requiredMark="optional">
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                message: "Kindly fill the valid email id!"
                            }
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: "Please input your name!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: "Please input your username!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                        />
                    </Form.Item>

                    <Form.Item
                        name="companyName"
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: "Please input your username!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Company Name"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                        />
                    </Form.Item>


                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block={true} type="primary" htmlType="submit" onClick={handleSignUp}>
                            Sign up
                        </Button>
                        <div className='loginText'>
                            <Text>Already have an account?</Text>
                            <span className='link' onClick={() => {
                                setIsSignUp(false);
                                setHasError(false)
                            }}> Login now</span>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>);
    }

    const { token } = useToken();


    return (
        <>
            <div className='loginContainer'>
                <div className='welcomeContent'>
                    <div className='content'>
                        {!screens.xs && <img src={ease} alt="sign" />}
                        {!screens.xs && <h4 className='loginTitle'>Welcome to Ease Margin</h4>}
                        <div>
                            Streamline your manufacturing process, create documents for your sales and purchases, manage inventory, fetch real-time data, and generate reports as per requirement.
                        </div>
                        <h4 className='loginTitle'>Why Choose Us?</h4>
                        <ul>
                            <li><strong>Enhance Manufacturing Performance</strong>: Ease Margin's solution helps to improve productivity for small and medium-sized manufacturers.</li>
                            <li><strong>Comprehensive Features</strong>: Real-time inventory and production management, sales and purchase tracking, and effective user management.</li>
                            <li><strong>Powerful Reporting</strong>: Provides advanced and accurate reporting tools for making decisions based on data.</li>
                            <li><strong>Easy Integration</strong>: Connects with multiple accounting software and API access for integrations based on need.</li>
                            <li><strong>User-Friendly Experience</strong>: Provides an easy interface, customization options, and all-time high-quality user support for a seamless experience.</li>
                        </ul>
                        <h4 className='loginTitle'>Get Started</h4>
                        <div>Log in to your account to take control of your business processes. If you're new, sign up to experience the future of business management today!</div>
                    </div>
                </div>
                <div className='loginForm'>
                    {isSignUp ? signUpForm() : loginForm()}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    loginData: state.api.login,
    signupData: state.api.signup
});

const mapDispatchToProps = (dispatch: any) => ({
    setAppLocation: (payload: any) => dispatch(setAppLocation(payload)),
    loginUser: (payload: any) => dispatch(loginUser(payload)),
    signupUser: (payload: any) => dispatch(signupUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
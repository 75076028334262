import {combineReducers } from 'redux'
import appLocationReducer from './appLocationReducer';
import {getCategoriesReducer } from './categoriesProductsReducer';
import login from './login'
import {teamsReducer, addTeamsReducer, editTeamsReducer, deleteTeamsReducer} from './teams';
import { getStoreReducer, addStoreReducer, editStoreReducer, deleteStoreReducer, getStoreByIdReducer } from './storeReducer';
import { addAddressReducer, deleteAddressReducer, editAddressReducer, getAddressReducer } from './addressReducer';
import { addBlogReducer, deleteBlogReducer, editBlogReducer, getBlogByIdReducer, getBlogsReducer } from './blogs';
import { imageReducer } from './imageUpload';
import { addUserReducer, deleteUserReducer, editUserReducer, usersReducer } from './users';
import {addBlogCommentReducer, deleteBlogCommentReducer, approveCommentsReducer,getApprovedBlogCommentsReducer, getBlogCommentstoBeApproveReducer, getAllBlogsApprovedCommentsReducer} from './commentReducer'
import { addItemsReducer, deleteItemsReducer, editItemsReducer, itemsReducer } from './items';
import { addBuyerSupplierReducer, buyerSupplierReducer, deleteBuyerSupplierReducer } from './buyerSupplier';
import { deleteBuyerSupplier } from '../actions/API/buyerSupplier';
import signupReducer from './signup';

const UIReducers = combineReducers({
    appLocation: appLocationReducer,
});
const APIReducers = combineReducers({
    login: login,
    signup: signupReducer,

    addBlog: addBlogReducer,
    editBlog: editBlogReducer,
    deleteBlog: deleteBlogReducer,
    getBlogs: getBlogsReducer,
    getBlogById: getBlogByIdReducer,

    getCategories: getCategoriesReducer,
    imageUpload : imageReducer,

    addTeam: addTeamsReducer,
    getTeams: teamsReducer,
    editTeam: editTeamsReducer,
    deleteTeam: deleteTeamsReducer,

    addUser: addUserReducer,
    getUsers: usersReducer,
    editUser: editUserReducer,
    deleteUser: deleteUserReducer,

    addItems: addItemsReducer,
    getItems: itemsReducer,
    editItems: editItemsReducer,
    deleteItems: deleteItemsReducer,

    addBuyerSupplier: addBuyerSupplierReducer,
    getBuyerSupplier: buyerSupplierReducer,
    deleteBuyerSupplier: deleteBuyerSupplierReducer,

    addStore: addStoreReducer,
    getStore: getStoreReducer,
    getStoreById: getStoreByIdReducer,
    editStore: editStoreReducer,
    deleteStore: deleteStoreReducer,

    addAddress : addAddressReducer,
    getAddress: getAddressReducer,
    editAddress : editAddressReducer,
    deleteAddress: deleteAddressReducer,

    addBlogComment : addBlogCommentReducer,
    deleteBlogComment: deleteBlogCommentReducer,
    approveComments: approveCommentsReducer,
    getApprovedBlogComments: getApprovedBlogCommentsReducer,
    getBlogCommentstoBeApprove : getBlogCommentstoBeApproveReducer,
    getAllBlogsApprovedComments : getAllBlogsApprovedCommentsReducer,
    
});

const RootReducer = combineReducers({
    api: APIReducers,
    ui: UIReducers
});

export default RootReducer;
import { Breadcrumb, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import Loader from "../../Home/Loader/Loader";
import DashboardCounters from "./DashboardCounters";



export default function Dashboard() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);

    return <>
        <Loader loading={loading}></Loader>
        <Layout
            style={{
                padding: '0 24px 24px',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumb
                    style={{
                        margin: '16px 0',
                        flex: 1, // Take up remaining space
                    }}
                >
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <Content
                style={{
                    paddingTop: 10,
                    margin: 0,
                    paddingLeft: 0,
                    minHeight: 280,
                    background: "#f5f5f5",
                    borderRadius: '10px',
                }}
            >
                <DashboardCounters />
            </Content>
        </Layout>
    </>;
}

import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  List,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { ChangeEvent } from "react";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import {
  addAddress,
  deleteAddress,
  editAddress,
  getAddress,
} from "../../redux/actions/API/addressActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { AppConstants } from "../../Appconstants";
import './Addressmanagement.css';
import {
  EditOutlined, DeleteOutlined
} from "@ant-design/icons";
import { stateData } from "../utility/StateData";
type NotificationType = "success" | "info" | "warning" | "error";

const { Option } = Select;

interface IAddressState {
  drawerOpen: boolean;
  editItem: any | null;
  submittedData: any[];
  addressLineOne: string;
  addressLineTwo: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  isEditAddress: boolean;
  loading: boolean;
  forTitle: boolean;
  searchData: string;
  filteredData: any;
  selectedCountry: string;
  statesList: string[];
  addressType: number[];
}

interface IAddressProps {
  addressData: any;
  getAddressState: IDataResponse;
  addAddressState: IDataResponse;
  editAddressState: IDataResponse;
  deleteAddressState: IDataResponse;
  userId: number;
  companyId: any;
  addAddress: Function;
  getAddress: Function;
  editAddress: Function;
  deleteAddress: Function;
}

const states: Record<string, string[]> = stateData;

class Addressmanagement extends React.Component<IAddressProps, IAddressState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: IAddressProps) {
    super(props);
    this.state = {
      drawerOpen: false,
      forTitle: true,
      submittedData: [],
      editItem: null,
      addressLineOne: "",
      addressLineTwo: "",
      pincode: "",
      city: "",
      state: "",
      country: "India",
      isEditAddress: true,
      loading: true,
      searchData: "",
      filteredData: this.props.addressData,
      selectedCountry: "India",
      statesList: states["India"],
      addressType: []
    };
  }

  getAddressType = (addressType: number) => {
    return addressType ===
      AppConstants.ADDRESS_TYPE_MAPPING.BILLING_ADDRESSES.ENTITY_ID
      ? AppConstants.ADDRESS_TYPE_MAPPING.BILLING_ADDRESSES.DISPLAY_STRING
      : AppConstants.ADDRESS_TYPE_MAPPING.DELIVERY_ADDRESSES.DISPLAY_STRING;
  };

  generateBindData = () => {
    return this.state.filteredData.map((address: any) => ({
      addressType: this.getAddressType(address.addressType),
    }));
  };

  componentDidMount(): void {
    this.props.getAddress({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<IAddressProps>,
    prevState: Readonly<IAddressState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getAddressState?.loading &&
      !this.props.getAddressState?.loading
    ) {
      if (this.props.getAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add address");
      } else {
        this.setState({
          loading: false,
          filteredData: this.filterData(this.props.addressData),
        });
      }
    }

    if (
      prevProps.addAddressState?.loading &&
      !this.props.addAddressState?.loading
    ) {
      if (this.props.addAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Address added successfully");
      }
    }

    if (
      prevProps.editAddressState?.loading &&
      !this.props.editAddressState?.loading
    ) {
      if (this.props.editAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to update address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",
          "Address updated successfully"
        );
        this.setState({
          drawerOpen: false,
          isEditAddress: false,
          editItem: null,
        });
      }
    }

    if (
      prevProps.deleteAddressState?.loading &&
      !this.props.deleteAddressState?.loading
    ) {
      if (this.props.deleteAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",
          "Address deleted successfully"
        );
      }
    }
    if (prevProps.addressData !== this.props.addressData) {
      this.setState({
        filteredData: this.filterData(this.props.addressData),
      });
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  filterData = (data: any[]) => {
    return data.filter(
      (item: {
        addressType: string;
        addressLineOne: string;
        addressLineTwo: string;
        city: string;
        state: string;
        country: string;
        pincode: string;
      }) =>
        item.addressLineOne.toLowerCase().includes(this.state.searchData) ||
        (item.addressLineTwo
          ? item.addressLineTwo.toLowerCase().includes(this.state.searchData)
          : false) ||
        item.city.toLowerCase().includes(this.state.searchData) ||
        item.state.toLowerCase().includes(this.state.searchData) ||
        item.country.toLowerCase().includes(this.state.searchData) ||
        (item.pincode
          ? item.pincode.toLowerCase().includes(this.state.searchData)
          : false)
    );
  };

  onAddAddress = () => {
    this.setState({
      drawerOpen: true,
      editItem: null,
      forTitle: true,
      isEditAddress: false,
    });
  };

  onClose = () => {
    this.setState({
      drawerOpen: false,
      isEditAddress: false,
      editItem: null,
    });
    this.formRef.current?.resetFields();
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();

    this.setState({
      searchData: query,
      filteredData: this.props.addressData.filter((item: any) => {
        // convert addressType handle gracefully
        const addressTypeString = this.getAddressType(
          item.addressType
        ).toLowerCase();
        return (
          addressTypeString.includes(query) ||
          item.addressLineOne.toLowerCase().includes(query) ||
          (item.addressLineTwo
            ? item.addressLineTwo.toLowerCase().includes(query)
            : false) ||
          item.city.toLowerCase().includes(query) ||
          item.state.toLowerCase().includes(query) ||
          item.country.toLowerCase().includes(query) ||
          (item.pincode ? item.pincode.toLowerCase().includes(query) : false)
        );
      }),
    });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        if (this.state.isEditAddress) {
          // Edit existing address
          this.props.editAddress({
            companyId: Number(this.props.companyId),
            addressId: this.state.editItem.id,
            ip_address: "127.0.0.1",
            storeType: values.storeType,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType,
          });
        } else {
          this.props.addAddress({
            companyId: Number(this.props.companyId),
            ip_address: "127.0.0.1",
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType,
          });

          this.setState((prevState) => ({
            submittedData: [
              ...prevState.submittedData,
              {
                id: Date.now(),
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                pincode: values.pincode,
                storeType: values.storeType,
                city: values.city,
                state: values.state,
                country: values.country,
                addressType: values.addressType,
              },
            ],
          }));
        }
        this.formRef.current?.resetFields();
        this.setState({ drawerOpen: false, editItem: null });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (values: any) => {
    console.log(values);
    const addressTypeArray: string[] = [];
    const addressType = values.addressType;
    console.log(values.addressType);
    if (addressType && typeof addressType === "number") {

      if (addressType == 2) {
        addressTypeArray.push("Billing Address");
      }
      if (addressType == 1) {
        addressTypeArray.push("Delivery Address");
      }
    }

    this.setState(
      {
        drawerOpen: true,
        editItem: values,
        forTitle: false,
        isEditAddress: true,
        addressType: [values.addressType]
      },

      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pincode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: [values.addressType],
          });
        }
      }
    );
  };

  handleDelete = (storeId: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteAddress({
      storeId: storeId,
    });
  };

  handleStateChange = (value: string) => {
    this.setState({ state: value });
  };

  render() {
    const { drawerOpen, filteredData } = this.state;
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          className="layout-address-main"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Address Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search address"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={this.onAddAddress}
                style={{ marginLeft: "auto" }}
              >
                Add Address
              </Button>
            </div>
          </div>
          <Content
            className="content-section"
          >
            <div>
              <List
                className="list-container-address"
                itemLayout="horizontal"
                dataSource={filteredData}
                renderItem={(item: any, index: number) => (
                  <>
                    <List.Item
                      key={item.id}
                      actions={[
                        <div className="action-address">
                          <Tooltip title="Edit Details">
                            <p
                              onClick={() => this.handleEdit(item)}
                              className="actionIcons"
                            >
                              <EditOutlined />
                            </p>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure to delete this?"
                              onConfirm={() => this.handleDelete(item.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <p className="actionIcons"><DeleteOutlined /></p>
                            </Popconfirm>
                          </Tooltip>
                        </div>
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          <div>{this.generateBindData()[index].addressType}</div>
                        }
                        description=
                        {<div className="addressDesc">{item.addressLineOne}, {item.addressLineTwo}, {item.city}, {item.state}, {item.country} - {item.pincode}</div>}
                      />
                      <Tag color={'geekblue'} key={1}>
                        {'Set as Default'}
                      </Tag>
                    </List.Item>
                  </>
                )}
              />
            </div>
          </Content>
        </Layout>

        <Drawer
          visible={drawerOpen}
          title={this.state.forTitle ? "Add an address" : "Edit an address"}
          width={720}
          onClose={this.onClose}
          className="drawer-address"
          extra={
            <Space>
              <Button onClick={this.onClose}>Cancel</Button>
              <Button
                onClick={this.handleSubmit}
                type="primary"
                form="addressForm"
                key="submit"
                htmlType="submit"
              >
                {this.state.forTitle ? "Submit" : "Update"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="addressLineOne"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                >
                  <Input placeholder="Please enter an address" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="addressLineTwo" label="Address Line 2">
                  <Input placeholder="please enter address description" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[
                    { required: false, message: "Please enter a pincode" },
                    {
                      pattern: /^\d{6}$/,
                      message: "Pin code must be a 6-digit number",
                    },
                  ]}
                >
                  <Input placeholder="please enter pincode" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    { required: true, message: "Please enter your city" },
                    {
                      max: 35,
                      message: "City must be less than 35 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message:
                        "City must be a string with no numbers or special characters",
                    },
                  ]}
                >
                  <Input placeholder="please enter your city" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    { required: true, message: "Please enter your state" },
                  ]}
                >
                  <Select
                    placeholder="Select a state"
                    onChange={this.handleStateChange}
                    value={this.state.state}
                    disabled={!this.state.selectedCountry}
                  >
                    {this.state.statesList.map((state) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please select a country" },
                  ]}
                >
                  <Select
                    placeholder="Select a country"
                    value={"India"}
                  >
                    <Option value="India">India</Option>
                    {/* Add more countries here if needed */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="addressType"
              label="Address Type"
              rules={[{ required: true, message: "Please enter address type" }]}
            >
              <Checkbox.Group style={{ width: "100%", marginLeft: "0px" }}>
                <Row>
                  <Col span={12}>
                    <Checkbox value={1}>Delivery Address</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value={2}>Billing Address</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  addressData: state.api.getAddress?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getAddressState: state.api.getAddress,
  addAddressState: state.api.addAddress,
  editAddressState: state.api.editAddress,
  deleteAddressState: state.api.deleteAddress,
});

const mapDispatchToProps = (dispatch: any) => ({
  addAddress: (payload: any) => dispatch(addAddress(payload)),
  getAddress: (payload: any) => dispatch(getAddress(payload)),
  editAddress: (payload: any) => dispatch(editAddress(payload)),
  deleteAddress: (payload: any) => dispatch(deleteAddress(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Addressmanagement);

import { Breadcrumb, Button, FormInstance, Layout, notification, Popconfirm, Radio, Table, TableProps, Tag, Tooltip } from "antd";
import { Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { connect } from "react-redux";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import { addItems, deleteItems, editItems, getItems } from "../../redux/actions/API/items";
import { AppConstants } from "../../Appconstants";
import Loader from "../../Home/Loader/Loader";
import { EditOutlined, DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import './ItemManagement.css'

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IItemProps {
  itemsData: any,
  addItems: Function,
  getItems: Function,
  editItems: Function,
  userId: Number,
  addItemState: IDataResponse,
  getItemState: IDataResponse,
  editItemState: IDataResponse,
  companyId: Number,
  deleteItems: Function,
  deleteItemState: IDataResponse
}

interface IItemState {
  addEditItemOpen: boolean;
  loading: boolean,
  itemId: number,
  itemName: string,
  itemType: number,
  category: number,
  taxType: number,
  tax: number,
  metricsUnit: number,
  HSNCode: string,
  price: number,
  currentStock: number,
  minStock: number,
  maxStock: number,
  description: string,
  dataToEdit: any,
  isEdit: boolean
}

class ItemManagement extends React.Component<
  IItemProps,
  IItemState
> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IItemProps) {
    super(props);
    this.state = {
      addEditItemOpen: false,
      loading: true,
      itemId: 0,
      itemName: '',
      itemType: 0,
      category: 0,
      taxType: 0,
      tax: 0,
      metricsUnit: 0,
      HSNCode: '',
      price: 0,
      currentStock: 0,
      minStock: 0,
      maxStock: 0,
      description: '',
      dataToEdit: [],
      isEdit: false
    };
  }

  componentDidMount(): void {
    this.props.getItems({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(prevProps: Readonly<IItemProps>, prevState: Readonly<IItemState>, snapshot?: any): void {
    if (prevProps.getItemState?.loading && !this.props.getItemState?.loading) {
      if (this.props.getItemState?.error?.length > 0) {

      } else {
        this.setState({
          loading: false,
        })
      }
    }

    if (prevProps.addItemState?.loading && !this.props.addItemState?.loading) {
      if (this.props.addItemState?.error) {
        this.openNotificationWithIcon("error", this.props.addItemState?.error?.message);
        this.setState({
          loading: false
        })
      } else {
        this.props.getItems({
          companyId: this.props.companyId,
        });
        this.formRef.current?.resetFields();
        this.setState({
          addEditItemOpen: false,
          loading: false,
        });
        this.openNotificationWithIcon('success', 'Item added successfully');
      }
    }

    if (prevProps.editItemState?.loading && !this.props.editItemState?.loading) {
      if (this.props.editItemState?.error) {
        this.openNotificationWithIcon("error", this.props.editItemState?.error?.message);
        this.setState({
          loading: false
        })
      } else {
        this.props.getItems({
          companyId: this.props.companyId,
        });
        this.formRef.current?.resetFields();
        this.setState({
          addEditItemOpen: false,
          loading: false,
          isEdit: false
        });
        this.openNotificationWithIcon('success', 'Item updated successfully');
      }
    }

    if (prevProps.deleteItemState?.loading && !this.props.deleteItemState?.loading) {
      this.props.getItems({
        companyId: this.props.companyId,
      });
      this.setState({
        loading: false
      })
      this.openNotificationWithIcon('success', 'Item deleted successfully');
    }
  }

  private onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
  };

  columns = () => {
    return [
      {
        title: 'Item ID',
        dataIndex: 'itemId',
        key: 'itemId',
        sorter: true
      },
      {
        title: 'Name',
        dataIndex: 'itemName',
        key: 'itemName',
      },
      {
        title: 'Type',
        dataIndex: 'itemType',
        key: 'itemType',
        render: (_: any, record: any) => (
          <Tag color={record.itemType == 'Buy' ? 'green' : (record.itemType == 'Sell' ? 'blue' : 'purple')} key={1}>
            {record.itemType}
          </Tag>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'categoryToShow',
        key: 'categoryToShow',
      },
      {
        title: 'Metrics Unit',
        key: 'metricsUnit',
        dataIndex: 'metricsUnit',
      },
      {
        title: 'HSN Code',
        key: 'HSNCode',
        dataIndex: 'HSNCode',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price'
      },
      {
        title: 'Tax Type',
        key: 'taxTypeToShow',
        dataIndex: 'taxTypeToShow',
      },
      {
        title: 'Current Stock',
        key: 'currentStock',
        dataIndex: 'currentStock',
        render: (_: any, stockData: any) => {
          return <div className="alignedFlex">
            <div style={{ minWidth: "5px" }}>{stockData.minStock > stockData.currentStock ? <ArrowDownOutlined style={{ color: 'red' }} /> : (stockData.maxStock < stockData.currentStock ? <ArrowUpOutlined style={{ color: 'green' }} /> : '')}</div>
            <div>{stockData.currentStock}</div>
          </div>
        }
      },
      {
        title: 'Min Stock',
        key: 'minStock',
        dataIndex: 'minStock',
      },
      {
        title: 'Max Stock',
        key: 'maxStock',
        dataIndex: 'maxStock',
      },
      {
        title: 'Action',
        key: 'action',
        render: (_: any, record: any) => (
          <Space size="middle">
            <Tooltip title="Edit Details">
              <span className="actionIcons" onClick={() => {
                this.handleEdit(record);
              }}>
                <EditOutlined />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => this.handleDelete(record)}
                okText="Yes"
                cancelText="No"
              >
                <span className="actionIcons"><DeleteOutlined /></span>
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
      },
    ]
  };

  handleDelete = (record: any) => {
    this.setState({
      loading: true
    })
    this.props.deleteItems({
      itemId: record.id
    })
  }

  renderItemType = (itemType: string) => {
    return (<>
      <Tag color={itemType == 'Buy' ? 'geekblue' : (itemType == 'Sell' ? 'green' : 'loser')} key={1}>
        {itemType}
      </Tag>
    </>)
  }

  handleEdit = (record: any) => {
    this.setState({
      isEdit: true,
      addEditItemOpen: true
    }, () => {
      if (this.formRef.current) {
        let dataToEdit = {
          id: record.id,
          itemId: record.itemId,
          itemName: record.itemName,
          itemType: record.itemType == 'Buy' ? 1 : (record == 'Sell' ? 2 : 3),
          category: record.category == 'Raw Materials' ? 1 : (record == 'Finished Good' ? 2 : 3),
          taxType: record.taxType == 'Inclusive' ? 1 : 2,
          tax: record.tax,
          metricsUnit: record.metricsUnit == 'KGs' ? 1 : (record == 'Units' ? 2 : 3),
          HSNCode: record.HSNCode,
          price: record.price,
          currentStock: record.currentStock,
          minStock: record.minStock,
          maxStock: record.maxStock,
          description: record.description,
          companyId: this.props.companyId
        }
        this.setState({
          dataToEdit: dataToEdit
        });
        this.formRef.current.setFieldsValue(dataToEdit);
        this.formRef.current.setFieldsValue({
          category: record.category,
          taxType: record.taxType,
          metricsUnit: record.metricsUnit
        })
      }
    })
  }

  generateData = () => {
    let data: any[] = [];
    this.props.itemsData?.map((item: any) => {
      data.push({
        id: item.id,
        itemId: item.itemId,
        itemName: item.itemName,
        category: item.category,
        itemType: item.itemType == AppConstants.ITEM_TYPES.BUY.ITEM_TYPE_ID ? AppConstants.ITEM_TYPES.BUY.ITEM_TYPE : (item.itemType == AppConstants.ITEM_TYPES.SELL.ITEM_TYPE_ID ? AppConstants.ITEM_TYPES.SELL.ITEM_TYPE : AppConstants.ITEM_TYPES.BOTH.ITEM_TYPE),
        categoryToShow: item.category == AppConstants.ITEM_CATEGORIES.RAW_MATERIALS.ITEM_CATEGORY_ID ? AppConstants.ITEM_CATEGORIES.RAW_MATERIALS.ITEM_CATEGORY : (item.category == AppConstants.ITEM_CATEGORIES.FINISHED_GOODS.ITEM_CATEGORY_ID ? AppConstants.ITEM_CATEGORIES.FINISHED_GOODS.ITEM_CATEGORY : AppConstants.ITEM_CATEGORIES.CONSUMABLES.ITEM_CATEGORY),
        metricsUnit: item.metricsUnit == AppConstants.ITEM_METRICS.KGS.ITEM_METRIC_ID ? AppConstants.ITEM_METRICS.KGS.ITEM_METRIC : (item.metricsUnit == AppConstants.ITEM_METRICS.UNITS.ITEM_METRIC_ID ? AppConstants.ITEM_METRICS.UNITS.ITEM_METRIC : AppConstants.ITEM_METRICS.METERS.ITEM_METRIC),
        HSNCode: item.HSNCode,
        taxTypeToShow: item.taxType == AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX_ID ? AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX + "(" + item.tax + "%)" : AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX + "(" + item.tax + "%)",
        taxType: item.taxType,
        tax: item.tax,
        price: item.price,
        minStock: item.minStock,
        maxStock: item.maxStock,
        currentStock: item.currentStock,
        description: item.description
      })
    });
    return data;
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  handleItemSubmit = () => {
    this.setState({
      loading: true,
    });
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        console.log(values);
        let dataToSend: any = {
          itemId: values.itemId,
          itemName: values.itemName,
          itemType: Number(values.itemType),
          category: Number(values.category),
          taxType: Number(values.taxType),
          tax: Number(values.tax),
          metricsUnit: typeof (values.metricsUnit) === 'string'
            ? values.metricsUnit === 'KGs'
              ? 1
              : (values.metricsUnit === 'Units'
                ? 2
                : 3)
            : values.metricsUnit,

          HSNCode: values.HSNCode,
          price: Number(values.price),
          currentStock: Number(values.currentStock),
          minStock: Number(values.minStock),
          maxStock: Number(values.maxStock),
          description: values.description,
          companyId: Number(this.props.companyId)
        }

        if (this.state.isEdit) {
          dataToSend.id = this.state.dataToEdit.id;
        }
        this.setState(dataToSend, () => {
          this.state.isEdit ? this.props.editItems(dataToSend) : this.props.addItems(dataToSend);
        });
      })
      .catch((errorInfo) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            className="div-head-item"
          >
            <Breadcrumb
              className="Item-Breadcrumb"
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Item Management</Breadcrumb.Item>
            </Breadcrumb>

            <div className="inputDivItem">
              <Input
                placeholder="Search Item"
                className="inputSearchItem"
              />
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    addEditItemOpen: true,
                  });
                }}
                className="buttonAddItem"
                style={{ marginLeft: "auto" }}
              >
                Add Item
              </Button>
            </div>
          </div>
          <Content
            className="content-section"
          >
            <Table columns={this.columns()} dataSource={this.generateData()} showSorterTooltip={{ target: 'sorter-icon' }} />
          </Content>
        </Layout>

        <Drawer
          title={this.state.isEdit ? 'Edit an Item' : 'Add an Item'}
          width={720}
          onClose={() => {
            this.setState({
              addEditItemOpen: false,
              isEdit: false
            });
            this.formRef.current?.resetFields();
          }}
          open={this.state.addEditItemOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    addEditItemOpen: false,
                    isEdit: false
                  });
                  this.formRef.current?.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.handleItemSubmit} type="primary">
                {this.state.isEdit ? 'Update' : 'Submit'}
              </Button>
            </Space>
          }
        >
          <Form layout="vertical" onFinish={this.handleItemSubmit} ref={this.formRef}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="itemId"
                  label="Item ID"
                  rules={[{ required: true, message: "Please enter item ID" }]}
                >
                  <Input placeholder="Please enter item ID" disabled={this.state.isEdit} onChange={(event: any) => {
                    this.setState({ itemId: event.target.value });
                  }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="itemName"
                  label="Item Name"
                  rules={[
                    { required: true, message: "Please enter item name" },
                  ]}
                >
                  <Input placeholder="Please enter item name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="itemType"
                  label="Item type"
                  rules={[
                    { required: true, message: "Please select an owner" },
                  ]}
                >
                  <Radio.Group onChange={() => { }} value={""}>
                    <Radio value={1} defaultChecked={this.state.itemType == 1}>Buy</Radio>
                    <Radio value={2} defaultChecked={this.state.itemType == 2}>Sell</Radio>
                    <Radio value={3} defaultChecked={this.state.itemType == 3}>Both</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    { required: true, message: "Please choose the category" },
                  ]}
                >
                  <Select
                    placeholder="Please choose the category"
                    options={[
                      {
                        value: 1,
                        label: "Raw Materials",
                      },
                      {
                        value: 2,
                        label: "Finished Good",
                      },
                      {
                        value: 3,
                        label: "Consumables",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="metricsUnit"
                  label="Unit of Metrics"
                  rules={[{ required: true, message: "Please choose the UOM" }]}
                >
                  <Select
                    placeholder="Please choose the UOM"
                    options={[
                      {
                        value: 1,
                        label: "KGs",
                      },
                      {
                        value: 2,
                        label: "Units",
                      },
                      {
                        value: 3,
                        label: "Meters",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="HSNCode"
                  label="HSN (Harmonised system of nomenclature)"
                  rules={[{ required: true, message: "Please enter HSN code" }]}
                >
                  <Input placeholder="Please enter HSN code" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    { required: true, message: "Please enter unit price" },
                  ]}
                >
                  <Input placeholder="Please enter unit price" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="taxType"
                  label="Tax type"
                  rules={[{ required: true, message: "Please choose the tax" }]}
                >
                  <Select
                    placeholder="Please choose the tax"
                    options={[
                      {
                        value: 1,
                        label: "Inclusive",
                      },
                      {
                        value: 2,
                        label: "Exclusive",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="tax"
                  label="Tax"
                  rules={[
                    { required: true, message: "Please enter tax" },
                  ]}
                >
                  <Input placeholder="Please enter tax" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="currentStock"
                  label="Current Stock"
                  rules={[
                    { required: true, message: "Please enter current stock" },
                  ]}
                >
                  <Input placeholder="Please enter current stock" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="minStock"
                  label="Min Stock"
                  rules={[{ required: true, message: "Please enter min stock" }]}
                >
                  <Input placeholder="Please enter min stock" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="maxStock"
                  label="Max Stock"
                  rules={[
                    { required: true, message: "Please enter max stock" },
                  ]}
                >
                  <Input placeholder="Please enter max stock" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Please enter description"
                  />
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  getItemState: state.api.getItems,
  addItemState: state.api.addItems,
  editItemState: state.api.editItems,
  deleteItemState: state.api.deleteItems,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
  addItems: (payload: any) => dispatch(addItems(payload)),
  editItems: (payload: any) => dispatch(editItems(payload)),
  deleteItems: (payload: any) => dispatch(deleteItems(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemManagement);
import React, { useState } from "react";
import {
 
  Row,
  Col, 
  Button,
  Input,
  
  Tooltip,
} from "antd";
import {
  InfoCircleOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "./TermsAndCondition.css";

const { TextArea } = Input;

const TermsAndCondition: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = () => {
    setIsEditing(false);
    setTimeout(() => {}, 5000);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <div className="terms-and-conditions">
      <div className="terms-text">
        Terms and Condition &nbsp;
        <Tooltip title="Terms and Condition">
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <div className="textArea-container">
            <TextArea
              rows={10}
              defaultValue={`Terms and Conditions for Sale Quotation
  
  1. Validity of Quotation
    This quotation is valid for a period of [number] days from the date of issue. Prices and availability of items are subject to change after this period.
  2. Prices
    All prices are quoted in [currency] and exclude applicable taxes, duties, and shipping charges unless otherwise stated. Final pricing will include all applicable taxes and fees.
            `}
              className="termCondition-textarea"
              maxLength={1000}
              showCount
            />
            <Tooltip title={isEditing ? "Edit" : "Saved"}>
              <Button
                className="edit-button"
                icon={
                  isEditing ? (
                    <EditOutlined />
                  ) : (
                    <CheckOutlined style={{ color: "green" }} />
                  )
                }
                onClick={isEditing ? handleSave : handleEdit}
              />
            </Tooltip>
          </div>
        </Col>

        <Col span={12}>
          <div className="totalCalculation">
            <div className="totals">
              <div className="total-item">
                <span className="label">Total</span>
                <span className="value">₹ 1000</span>
              </div>
              <div className="total-item">
                <span className="label">Tax (GST)</span>
                <span className="value">₹ 180</span>
              </div>
              <div className="total-item">
                <span className="label">Net total after tax</span>
                <span className="value">₹ 1180</span>
              </div>
              <div className="total-item grand-total">
                <span className="label">Grand Total</span>
                <span className="value">₹ 1180</span>
              </div>
              <div className="total-item">
                <span className="label">Advance Payment</span>
                <span className="value">
                  <input type="text" placeholder="Advance Payment" />
                </span>
              </div>

              <div className="button-group">
                <Button type="primary" style={{ marginRight: 8 }}>
                  Save Draft
                </Button>
                <Button type="primary" className="button-large">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TermsAndCondition;

import {
  Avatar,
  Breadcrumb,
  Input,
  Layout,
  List,
  notification,
  Popconfirm,
  Select,
  Badge,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import "./CommentsCollections.css";
import { connect } from "react-redux";
import {
  approveComments,
  deleteBlogComment,
  getAllBlogsApprovedComments,
  getApprovedBlogComments,
  getBlogCommentstoBeApprove,
} from "../../../redux/actions/API/commentActions";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";

type NotificationType = "success" | "info" | "warning" | "error";

interface ICommentsCollectionsProps {
  toBeApprovedCommentsData: any;
  allBlogApprovedCommentsData: any;
  getApprovedBlogComments: Function;
  getApprovedBlogCommentsState: IDataResponse;
  getBlogCommentstoBeApprove: Function;
  getBlogCommentstoBeApproveState: IDataResponse;
  deleteBlogComment: Function;
  deleteBlogCommentState: IDataResponse;
  approveComments: Function;
  approveCommentsState: IDataResponse;
  getAllBlogsApprovedComments: Function;
  getAllBlogsApprovedCommentsState: IDataResponse;
}

const CommentsCollections: React.FC<ICommentsCollectionsProps> = ({
  toBeApprovedCommentsData,
  allBlogApprovedCommentsData,
  getApprovedBlogComments,
  getApprovedBlogCommentsState,
  getBlogCommentstoBeApprove,
  getBlogCommentstoBeApproveState,
  getAllBlogsApprovedComments,
  getAllBlogsApprovedCommentsState,
  deleteBlogComment,
  deleteBlogCommentState,
  approveComments,
  approveCommentsState,
}) => {
  const prevPropsRef = useRef<ICommentsCollectionsProps>();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("toBeApprove");

  useEffect(() => {
    getBlogCommentstoBeApprove();
  }, []);

  useEffect(() => {
    if (filterType === "toBeApprove") {
      getBlogCommentstoBeApprove();
    } else if (filterType === "approved") {
      getAllBlogsApprovedComments();
    }
  }, [filterType]);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getBlogCommentstoBeApproveState?.loading &&
      !getBlogCommentstoBeApproveState?.loading
    ) {
      if (getBlogCommentstoBeApproveState?.error?.length > 0) {
        openNotificationWithIcon(
          "error",
          "Failed to fetch comments for approval"
        );
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.getAllBlogsApprovedCommentsState?.loading &&
      !getAllBlogsApprovedCommentsState?.loading
    ) {
      if (getAllBlogsApprovedCommentsState?.error?.length > 0) {
        openNotificationWithIcon("error", "Failed to fetch approved comments");
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.approveCommentsState?.loading &&
      !approveCommentsState?.loading
    ) {
      if (approveCommentsState?.error?.length > 0) {
        openNotificationWithIcon("error", "Failed comments for approval");
      } else {
        openNotificationWithIcon("success", "Comments approved successfully");
        setLoading(false);
        getBlogCommentstoBeApprove();
      }
    }

    if (
      prevPropsRef?.current?.deleteBlogCommentState?.loading &&
      !deleteBlogCommentState?.loading
    ) {
      if (deleteBlogCommentState?.error?.length > 0) {
        openNotificationWithIcon("error", "Failed to delete comment!");
      } else {
        openNotificationWithIcon("success", "Comments Deleted successfully");
        // both api calling because of caching issues.
        getBlogCommentstoBeApprove();
        getAllBlogsApprovedComments();
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      toBeApprovedCommentsData,
      allBlogApprovedCommentsData,
      getApprovedBlogComments,
      getApprovedBlogCommentsState,
      getBlogCommentstoBeApprove,
      getBlogCommentstoBeApproveState,
      getAllBlogsApprovedComments,
      getAllBlogsApprovedCommentsState,
      deleteBlogComment,
      deleteBlogCommentState,
      approveComments,
      approveCommentsState,
    };
  }, [
    getApprovedBlogCommentsState,
    getBlogCommentstoBeApproveState,
    getAllBlogsApprovedCommentsState,
    deleteBlogCommentState,
    approveCommentsState,
  ]);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description?: string
  ) => {
    notification[type]({
      message: message,
      description: description,
      duration: 3,
    });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleDelete = (item: any) => {
    setLoading(true);
    deleteBlogComment({ commentId: item.id });
  };

  const handleApprove = (item: any) => {
    setLoading(true);
    approveComments({ commentId: item.id });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleFilterChange = (value: string) => {
    setLoading(true);
    setFilterType(value);
    if (value === "toBeApprove") {
      getBlogCommentstoBeApprove();
    } else if (value === "approved") {
      getAllBlogsApprovedComments();
    }
  };

  const dataToFilter =
    filterType === "approved"
      ? allBlogApprovedCommentsData
      : toBeApprovedCommentsData;

  const filteredComments = dataToFilter?.filter((comment: any) => {
    const matchesType =
      filterType === "approved"
        ? comment.status === 1
        : filterType === "toBeApprove"
        ? comment.status === 0
        : true;

    const searchValue = searchText.toLowerCase();

    const matchesSearchText =
      comment.comments.toLowerCase().includes(searchValue) ||
      (comment.fullName &&
        comment.fullName.toLowerCase().includes(searchValue)) ||
      `Username ${comment.id}`.toLowerCase().includes(searchValue);

    return matchesType && matchesSearchText;
  });

  // comment.status === 0
  const hasUnapprovedComments = filteredComments?.some(
    (comment: any) => comment.status === 0
  );

  return (
    <>
      <Loader loading={loading}></Loader>
      <Layout
        style={{
          padding: "0 24px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb
            style={{
              margin: "16px 0",
              flex: 1,
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Comments</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Select
              showSearch
              placeholder="Search by type"
              optionFilterProp="label"
              onChange={handleFilterChange}
              value={filterType}
              style={{ marginRight: "20px" }}
              options={[
                {
                  value: "toBeApprove",
                  label: "To be approve",
                },
                {
                  value: "approved",
                  label: "Approved",
                },
              ]}
            />
            <Input placeholder="Search comment" onChange={handleSearchChange} />
          </div>
        </div>
        <Content
          style={{
            paddingTop: 10,
            margin: 0,
            paddingLeft: 0,
            paddingRight: "20px",
            minHeight: 280,
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={filteredComments}
            renderItem={(item: any) => (
              <List.Item
                key={item.id}
                actions={[
                  item.status === 0 && hasUnapprovedComments && (
                    <div
                      className="approve"
                      onClick={() => handleApprove(item)}
                      key="list-loadmore-edit"
                    >
                      Approve
                    </div>
                  ),
                  <Popconfirm
                    title="Are you sure to delete this?"
                    onConfirm={() => handleDelete(item)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="delete">Delete</div>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.id}`}
                    />
                  }
                  title={
                    <div>
                      <span>{item.fullName || `Username ${item.id}`}</span>
                      {item.status === 1 && (
                        <Tooltip title="This comment has been approved">
                          <Badge
                            count="Approved"
                            style={{
                              backgroundColor: "#52c41a",
                              marginLeft: 8,
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  }
                  description={
                    <>
                      <div style={{ color: "gray", fontSize: "0.85em" }}>
                        Created At:{" "}
                        {item.createdAt
                          ? formatDate(item.createdAt)
                          : "Unknown"}
                      </div>
                      <div>{item.comments}</div>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  toBeApprovedCommentsData:
    state.api.getBlogCommentstoBeApprove?.data?.reverse(),
  allBlogApprovedCommentsData:
    state.api.getAllBlogsApprovedComments?.data?.reverse(),
  getApprovedBlogCommentsState: state.api.getApprovedBlogComments,
  getBlogCommentstoBeApproveState: state.api.getBlogCommentstoBeApprove,
  getAllBlogsApprovedCommentsState: state.api.getAllBlogsApprovedComments,
  deleteBlogCommentState: state.api.deleteBlogComment,
  approveCommentsState: state.api.approveComments,
});

const mapDispatchToProps = (dispatch: any) => ({
  getApprovedBlogComments: (payload: any) =>
    dispatch(getApprovedBlogComments(payload)),
  getBlogCommentstoBeApprove: () => dispatch(getBlogCommentstoBeApprove()),
  getAllBlogsApprovedComments: () => dispatch(getAllBlogsApprovedComments()),
  deleteBlogComment: (payload: any) => dispatch(deleteBlogComment(payload)),
  approveComments: (payload: any) => dispatch(approveComments(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsCollections);
